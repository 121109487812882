.landing-page {
  font-family: "Roboto", sans-serif;

  &__content {
    transition: transform var(--deep-dive-transition-duration);
    &.move-to-left {
      transform: translateX(
        calc(var(--deep-dive-actual-width) * -1 + var(--deep-dive-overlap))
      );
    }
  }
}
.banner-video {
  // height: calc(9 * 100vw / 16 - 6px);
  height: 100vh;
  background-color: var(--primary-color);
  // background-color: #434343;
  // margin-top: -#{$header-height};
  // background-image: url("../../assets/images/banner-video.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &__video-content {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    video {
      width: 100%;
      height: 100%;
    }
  }

  &__content {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 0 12px;
    width: 100%;
  }
  &__title {
    font-weight: 700;
    font-size: 60px;
    line-height: 1.4;
    color: #fff;
    padding-bottom: 36px;
    text-transform: capitalize;
  }

  &__sub-title {
    font-weight: 700;
    font-size: 26px;
    line-height: 40px;
    color: #fff;
    padding-bottom: 22px;
  }

  &__description {
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    padding-bottom: 30px;
    max-width: 600px;
    text-align: center;
  }

  &__button-wrapper {
    gap: 20px;
    width: 100%;
    justify-content: center;
    > a {
      flex-basis: 200px;
      display: flex;
    }
    .mt-button {
      height: 52px;
      // max-width: 220px;
      // width: 100%;
      flex-basis: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;

      // &:hover {
      //   svg {
      //     -webkit-animation-name: heartBeat;
      //     animation-name: heartBeat;
      //   }
      // }

      // svg {
      //   width: 30px;
      //   height: 20px;

      //   -webkit-animation-duration: 1s;
      //   animation-duration: 1s;
      //   -webkit-animation-duration: var(--animate-duration);
      //   animation-duration: var(--animate-duration);
      //   -webkit-animation-fill-mode: both;
      //   animation-fill-mode: both;
      //   animation-iteration-count: infinite;
      //   -webkit-animation-iteration-count: infinite;
      // }
    }
  }
}

.lp-intro {
  background-color: var(--platium);
  padding: 130px 0;

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__image {
    max-width: 560px;
    img {
      width: 100%;
      height: 60px;
    }
  }

  &__title {
    color: var(--secondary-color);
    font-weight: 700;
    font-size: 32px;
    line-height: 1.25;
    padding-top: 60px;
  }

  &__description {
    padding-top: 16px;
    padding-bottom: 60px;
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    max-width: 700px;
  }

  .mt-button {
    height: 46px;
  }
}

.lp-htu {
  &__title {
    font-weight: 600;
    font-size: 58px;
    line-height: 40px;
    color: #fff;
    background-image: linear-gradient(
      61.26deg,
      var(--secondary-color) 51.92%,
      var(--primary-color) 82.79%
    );
    padding-top: 88px;
    padding-bottom: 50px;
    text-align: center;
  }

  &__image {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: -1px;

    img {
      position: relative;
      z-index: 2;
      width: 100%;
      max-width: 676px;
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 50%;
      background-image: linear-gradient(
        61.26deg,
        var(--secondary-color) 51.92%,
        var(--primary-color) 82.79%
      );
    }
  }
}

.lp-function {
  padding-top: 60px;
  &__title {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    color: var(--secondary-color);
    text-align: center;
    padding-bottom: 40px;
  }

  &__list {
    display: flex;
    align-items: flex-start;
    // margin: 0 -40px;
    &-item {
      padding: 0 40px;
      flex: 1;

      img {
        width: 100%;
        margin: 0 auto;
      }

      .content {
        position: relative;
      }

      &-1 {
        img {
          max-width: 278px;
        }

        .top {
          padding-bottom: 10px;
        }

        .bottom {
          // position: absolute;
          // bottom: -30px;
          // right: 20px;
          padding-top: 10px;
          text-align: right;
        }
      }

      &-2 {
        img {
          max-width: 217px;
          // max-height: 262px;
        }

        .lp-function__text {
          padding-bottom: 45px;
          max-width: 217px;
          margin: 0 auto;
        }
      }

      &-3 {
        img {
          // max-width: 264px;
          // height: 180px;
          // width: auto;
        }

        .lp-function__text {
          padding-bottom: 65px;
        }
      }
    }
  }

  &__detail {
    background-color: var(--anti-flash);
    border-radius: 20px;
    margin-top: 40px;

    .desktop {
      display: block;
    }

    .mobile {
      display: none;
    }

    &__inner {
      display: flex;
      align-items: center;
    }

    &__content {
      padding-left: 8.4%;
      padding-right: 5.7%;
      flex: 1;
    }

    &__image {
      background-color: var(--metalic-silver);
      border-radius: 20px;
      width: 380px;
      height: 480px;
      overflow: hidden;
      flex-shrink: 0;
      position: relative;

      &__title {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        padding: 18px 20px;
      }

      &-tag:not(.animate__animated) {
        opacity: 0;
      }

      img {
        width: 100%;
        max-width: 380px;
        position: absolute;
        bottom: 0;
      }
    }

    &__title {
      font-weight: 600;
      font-size: 32px;
      line-height: 40px;
      color: var(--font-color);
      padding-bottom: 16px;
    }
    &__description {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      padding-bottom: 40px;
    }

    .mt-button {
      height: 46px;
    }

    &:nth-child(even) {
      .lp-function__detail {
        &__image {
          order: 2;
        }
        &__content {
          order: 1;
          padding-right: 8.4%;
          padding-left: 5.7%;
        }
      }
      // &__image {
      //   order: 2;
      // }
      // &__content {
      //   order: 1;
      // }
    }
  }

  &__text {
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    color: var(--primary-color);
  }

  &__note {
    font-size: 12px;
    font-weight: 500;
    color: var(--primary-color);
    padding-bottom: 30px;
    text-align: right;
    margin-top: -16px;
  }
}

.lp-banner-slider {
  height: calc(600 * 100vw / 1280);
  margin-top: 34px;
  margin-bottom: 56px;
  > div {
    height: 100%;
  }
  &__image {
    position: relative;
    display: flex;
    justify-content: center;
    img {
      width: 100%;
    }

    .mt-button {
      height: 46px;
      position: absolute;
      bottom: 30px;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background-color: rgba(var(--secondary-color-rgb), 0.4);
    &:after {
      font-size: 20px;
      font-weight: bold;
      color: #fff;
    }
  }

  .swiper-button-prev {
    left: 40px;
  }
  .swiper-button-next {
    right: 40px;
  }
}

.lp-footer {
  clear: both;
  background-color: var(--footer-bg-color);
  padding: 24px 0;
  font-size: 12px;
  &__top {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 16px;
    width: 100%;
  }
  &__bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
  }

  &__inner {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 12px;
  }
  .logo {
    display: flex;
    flex-direction: column;
    gap: 8px;
    white-space: break-spaces;
  }

  .copy-right {
    @include text-body(xs);
  }

  .links-container {
    flex: 1;
    container-type: inline-size;
  }

  .links {
    display: grid;
    row-gap: 16px;
    justify-content: normal;
    grid-template-columns: repeat(2, auto);

    li {
      padding: 0 20px;
    }

    @container (width > 480px) {
      margin: 0;
      justify-content: center;
      grid-template-columns: repeat(3, auto);
    }

    @container (width > 700px) {
      padding: 0 16px;
      row-gap: 24px;
      justify-content: center;
      grid-template-columns: repeat(4, auto);
    }

    @media screen and (max-width: 576px) {
      margin-left: -20px;
      justify-content: normal;
    }

    a {
      color: var(--font-color);
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: -0.12px;
      text-transform: capitalize;
    }

    .ant-divider {
      border-color: var(--font-color);
      margin: 0 15px;
    }
  }

  .social-links {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    gap: 6px;
  }
  .contact-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1 1 0;
  }
  .contact {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    column-gap: 24px;

    .contact-group {
      max-width: 400px;
      width: calc(50% - 12px);
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 8px;
      justify-content: center;

      .contact-label {
        min-width: 110px;
        display: flex;
        gap: 8px;
        align-items: center;

        svg {
          width: 16px;
          height: 16px;
        }
      }
      .contact-content {
        display: flex;
        flex: 1;
        font-size: 12px;
        font-style: normal;
        line-height: 16px;
      }
    }
  }
  &__bottom-right {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: flex-end;
  }
  .image-container {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
  }
  .download-links {
    display: flex;
    gap: 8px;
    align-items: center;
  }
  .app-links {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
  .qr-code-links {
    display: flex;
    align-items: center;
  }
  .sale-noti-img {
    width: 134px;
    height: 50px;
  }
}

.lp-function-intro-section {
  background-color: #fff;
  padding-bottom: 20px;

  > .container {
    // max-width: 180px;
    // max-width: 1440px;
  }

  .function-intro {
    margin-top: 20px;

    .inner {
      background-color: var(--anti-flash);
      border-radius: 16px;
      padding: 24px;
      display: flex;
      align-items: center;
      height: 100%;
    }

    &__left {
      min-width: 370px;
      flex: 1;
      // max-width: 38%;
      .content {
        padding: 0 40px;
      }
    }

    &__right {
      background-color: #fff;
      border-radius: 20px;
      position: relative;
      flex: 1;
      max-width: 664px;
    }

    &__title {
      font-size: 28px;
      line-height: 1.2;
      font-weight: 600;
      padding-bottom: 12px;
      text-align: center;
      white-space: break-spaces;
    }

    &__desc {
      font-size: 18px;
      line-height: 1.4;
      text-align: center;
      white-space: break-spaces;
    }

    &:nth-child(even) {
      .function-intro {
        &__left {
          order: 1;
        }
      }
    }
  }

  @media screen and (min-width: 1366px) {
    .function-intro {
      &__left {
        flex: 1;
        max-width: none;
        .content {
          padding: 0 80px;
        }
      }
      &__right {
        max-width: 664px;
      }
    }
  }
}

.lp-specs {
  margin-bottom: 80px;
  > .container {
    max-width: 1180px;
  }
  &__inner {
    background-color: var(--secondary-color);
    border-radius: 20px;
    padding: 50px;
    text-align: center;
  }

  &__title {
    font-size: 26px;
    font-weight: 700;
    line-height: 40px;
    color: #fff;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 16px 16px 50px;
  }

  &__item {
    color: #fff;
    font-size: 16px;
    line-height: 20px;
    margin: 0 12px;
    padding: 4px 0;
    position: relative;
    display: flex;
    // align-items: center;
    justify-content: center;
    cursor: pointer;

    &.active,
    &:hover {
      &:after {
        width: 100%;
      }
    }

    &:after {
      display: block;
      content: "";
      position: absolute;
      bottom: -2px;
      width: 0;
      height: 2px;
      background-color: #fff;
      transition: 0.25s ease-in-out;
    }
  }

  &__ring-img {
    img {
      margin: 0 auto;
      max-width: 440px;
      width: 100%;
    }
  }
}

.lp-limitless {
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > .container {
    margin: 0 auto;
  }

  &__title {
    font-size: 34px;
    line-height: 1.2;
    font-weight: 600;
    color: var(--secondary-color);
    text-align: center;
    white-space: break-spaces;
  }
  &__description {
    padding-top: 24px;
    font-size: 20px;
    line-height: 1.4;
    text-align: center;
    margin: 0 auto;
    white-space: break-spaces;
    @media screen and (width > 1024px) {
      max-width: calc(100% * 8 / 12);
    }
  }
}

.lp-mansory {
  padding-top: 55px;
  height: 890px;
  > .container {
    max-width: 1180px;
  }
  > * {
    height: 100%;
    > div {
      margin: -8px;
      height: 100%;
    }
  }
  &__item {
    float: left;
    height: 50%;
    padding: 8px;
    width: calc(100% / 3);

    .inner {
      overflow: hidden;
      width: 100%;
      background-color: var(--anti-flash);
      border-radius: 20px;
      height: 100%;
      padding: 70px 22px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
    }

    &.first {
      height: 100%;
    }

    &.second {
      width: calc(100% * 2 / 3);
      .plus-icon {
        right: 22px;
        bottom: 22px;
      }

      .lp-mansory {
        &__image {
          padding-top: 80px;
          position: absolute;

          img {
            max-height: 425px;
          }
        }
      }
    }

    &.small {
      .inner {
        padding-top: 27%;
      }
    }
  }

  &__title {
    font-size: 42px;
    line-height: 50px;
    font-weight: 600;
    text-align: center;
    color: var(--secondary-color);

    > span {
      color: var(--metalic-silver);
    }
  }

  &__image {
    padding-top: 120px;
    > span {
      font-size: 12px;
      font-weight: 500;
      font-style: italic;
      padding-top: 30px;
      text-align: center;
      display: block;
    }
  }

  .plus-icon {
    width: 34px;
    height: 34px;
    border-radius: 34px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    bottom: 32px;
    cursor: pointer;
  }

  .lp-function__list-item {
    padding: 0;

    img {
      width: 90%;
      max-width: none;
    }
  }
}

.lp-mansory-detail {
  z-index: 100;
  position: fixed;
  top: 0;

  width: 100%;
  height: 100%;
  pointer-events: none;

  &.show {
    .inner {
      pointer-events: auto;
      transform: translateX(0);
      background-color: #fff;
    }
  }

  .inner {
    z-index: 2;
    position: absolute;
    width: var(--deep-dive-width);
    height: 100vh;
    // height: -webkit-fill-available;
    // height: fill-available;
    padding: var(--deep-dive-padding);
    padding-top: var(--header-height);
    box-sizing: border-box;
    transform: translateX(100%);
    transition: transform var(--deep-dive-transition-duration);
    pointer-events: none;

    right: 0;

    overflow: auto;
  }

  .close-icon {
    cursor: pointer;
    position: absolute;
    z-index: 100;
    top: 20px;
    right: 15px;
  }
}

.lp-lifestyle {
  .function-intro {
    margin-top: 0;

    &__left {
      order: 2;
      flex: 1;
      max-width: none;
      .content {
        padding-left: 80px;
        margin: 0 auto;
      }
    }
    &__right {
      order: 1;
      max-width: calc(100% / 12 * 4);
    }

    &__title {
      font-size: 28px;
      line-height: 1.2;
    }

    &__desc {
      font-size: 18px;
      line-height: 1.4;
    }

    .mt-media__video {
      padding-top: 150%;
    }
  }
  .mt-button {
    margin-top: 24px;
    width: 200px;
    height: 52px;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.2px;
  }
}

.use-ring {
  padding-top: 60px;
  &__content {
    display: flex;
    align-items: flex-start;
  }
  &__title {
    font-size: 28px;
    line-height: 30px;
    font-weight: 700;
    width: 280px;
    flex-shrink: 0;
  }

  &__desc {
    padding-left: 11%;
    font-size: 14px;
    line-height: 20px;
    color: var(--primary-color);
    p {
      margin-bottom: 6px;
    }
  }
}

.lp-tip {
  padding: 80px 0 60px;
  justify-content: center;
  .container > * {
    @media screen and (width > 1024px) {
      max-width: calc(100% * 10 / 12);
      margin: 0 auto;
    }
  }

  &__title {
    font-size: 34px;
    line-height: 1.2;
    text-align: center;
    font-weight: 600;
  }
  &__desc {
    font-size: 20px;
    line-height: 1.4;
    padding-top: 24px;
    text-align: justify;
  }
}

.lp-comments {
  padding-top: 60px;

  &__title {
    font-size: 26px;
    line-height: 35px;
    font-weight: 700;
    text-align: center;
  }
  &__list {
    padding-top: 50px;
    .swiper-slide {
      max-width: 440px;
    }

    --swiper-pagination-color: var(--secondary-color);
  }
}

.lp-comment {
  text-align: center;
  max-width: 440px;
  > .container {
    max-width: 1180px;
  }
  .inner {
    background: #ffffff;
    box-shadow: 10px 4px 40px rgba(17, 19, 24, 0.16);
    border-radius: 32px;
    padding: 0 40px 30px;
  }
  &__avatar {
    width: 108px;
    height: 108px;
    border-radius: 108px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 56px;
    background: #ffffff;
    box-shadow: 30px 21px 60px rgba(17, 19, 24, 0.08);
    position: relative;
    top: -54px;
    margin: 0 auto -54px;
  }
  &__name {
    font-size: 26px;
    font-weight: 600;
  }
  &__user-title {
    font-size: 10px;
    color: var(--metalic-silver);
  }
  &__content {
    font-size: 12px;
    line-height: 18px;
    padding-top: 16px;
  }

  &__rating {
    padding-top: 10px;
    width: 160px;
    margin: 0 auto;

    .rr--has-stroke {
      .rr--on,
      .rr--off {
        .rr--svg {
          stroke: #111318;
        }
      }
    }
    .rr--on .rr--svg {
      fill: #111318;
    }
    .rr--off .rr--svg {
      fill: #fff;
    }
  }
}

.lp-more {
  padding-top: 60px;
  padding-bottom: 32px;
  > .container {
    // max-width: 1440px;
  }
  .inner {
    align-items: center;
    justify-content: space-between;
  }

  &__left {
    text-align: center;
  }

  &__right {
    display: flex;
    align-items: stretch;
    gap: 24px;
    padding-top: 40px;
  }

  &__title {
    font-size: 34px;
    line-height: 1.2;
    padding-bottom: 16px;
    font-weight: 600;

    svg {
      margin-right: 16px;
    }
  }

  &__desc {
    font-size: 20px;
    line-height: 1.4;
    margin: 0 auto;
    @media screen and (width > 1024px) {
      max-width: calc(100% * 8 / 12);
    }
  }

  &__block {
    border-radius: 16px;
    background-color: var(--anti-flash);
    padding: 24px 32px;
    flex-basis: calc(100% / 3);

    .lp-more {
      &__title {
        font-size: 28px;
        line-height: 1.2;
        padding-bottom: 12px;
        display: flex;
        align-items: center;
        svg {
          width: 30px;
          height: 30px;
        }
      }

      &__desc {
        font-size: 18px;
        line-height: 1.4;
        max-width: none;
      }
    }
  }

  .mt-button {
    height: 36px;
  }

  &__button {
    padding-top: 30px;
  }
}

@media screen and (max-width: 1024px) {
  .use-ring {
    &__content {
      flex-direction: column;
    }

    &__desc {
      padding-left: 0;
      padding-top: 50px;
    }

    .lp-function {
      &__list {
        flex-wrap: wrap;

        &-item {
          flex: none;
          padding: 0;
          margin-bottom: 50px;
          img {
            max-width: none;
          }

          &-1 {
            width: 100%;

            .content {
              max-width: 350px;
              margin: 0 auto;
            }
          }
          &-2 {
            margin-right: 80px;
            .content {
              max-width: 220px;
            }
          }

          &-3 {
            .content {
              max-width: 300px;
            }
          }
        }
      }
      &__note {
        text-align: left;
      }
    }
  }

  .function-intro-list {
    display: flex;
    margin: 0 -8px;
    .function-intro {
      flex: 1;
      margin: 0 8px;
      width: calc(50% - 16px);
    }
  }

  .lp-function-intro-section {
    .function-intro {
      .inner {
        flex-direction: column;
        padding: 0;
      }

      &__left {
        padding-top: 50px;
        padding-bottom: 50px;
        max-width: none;
        flex: 1;
        min-width: 0;

        .content {
          padding: 0 25px;
        }
      }

      &__right {
        width: 100%;
        order: 2;
        flex: 0;
        flex-shrink: 0;
        max-width: none;
      }

      .mt-media__video {
        padding-top: calc(3 * 100% / 4);
        box-shadow: 1px 1px 10px rgba(17, 19, 24, 0.16);
        border-radius: 20px;
      }
    }
  }

  .lp-mansory {
    height: auto;
    padding-bottom: 16px;

    .inner {
      padding-top: 22px;
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
    }

    &__item {
      float: none;
      width: 50%;
      height: auto;

      &.first {
        height: auto;
      }

      &.second,
      &.small {
        width: 50%;
      }

      &.second {
        .lp-mansory {
          &__image {
            position: static;
            margin: 0 -40px;
            padding-top: 50px;
            padding-bottom: 100px;
          }
        }
        .plus-icon {
          right: auto;
        }
      }
      &.small {
        .inner {
          padding-top: 22px;
          padding-bottom: 130px;
        }
      }
    }

    &__image {
      padding-top: 50px;
      padding-bottom: 150px;

      span {
        display: none;
      }
    }
  }

  .lp-comments {
    &__list {
      .swiper-slide {
        max-width: 250px;
      }
    }
  }
}

@media screen and (max-width: 991.9px) {
  .lp-htu {
    &__image {
      img {
        max-width: 70vw;
      }
    }
  }
  .lp-function {
    .container {
      padding: 0;
    }

    &__title {
      padding-bottom: 30px;
    }
  }
}

@include media-breakpoint-down(lg) {
  .lp-more {
    &__right {
      // display: block;
      flex-direction: column;
      align-items: center;
      > div {
        max-width: 460px;
      }
    }
  }
  .lp-footer {
    .social-links {
      flex: none;
    }
  }
  .lp-footer {
    .contact {
      max-width: 500px;
      .contact-group {
        width: 100%;
      }
    }
  }
}
@include media-breakpoint-down(md) {
  .lp-more {
    &__left {
      padding: 0;
    }
  }
}

@media screen and (max-width: 767.98px) {
  .banner-video {
    height: 100vh;
    background-position: center;
  }

  .lp-intro {
    padding: 50px 0;

    &__title {
      padding-top: 50px;
    }

    &__description {
      padding: 16px 20px 50px;
    }
  }

  .lp-htu {
    &__title {
      font-size: 40px;
      padding-top: 50px;
      padding-bottom: 25px;
    }

    &__image {
      img {
        max-width: 80%;
      }
    }
  }

  .lp-function {
    &__detail {
      // max-width: 380px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 25px;

      .desktop {
        display: none;
      }

      .mobile {
        display: block;
      }

      &-section {
        padding: 0 15px;
      }

      &__inner {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &__title {
        display: none;
      }

      &__description {
        display: none;
      }

      &__content {
        padding: 0 !important;
        width: 100%;
        display: flex;
        justify-content: center;
      }

      &__image {
        // max-width: 380px;
        width: 100%;
        height: 500px;

        display: flex;
        align-items: center;
        flex-direction: column;

        &__title {
          font-weight: 600;
          font-size: 28px;
          line-height: 40px;
          padding: 24px 12px 12px;
          text-align: center;
        }

        &__description {
          font-size: 16px;
          line-height: 22px;
          padding: 0 30px;
          text-align: center;
        }

        &__button {
          position: absolute;
          bottom: 20px;
        }
      }

      &__actions {
        position: absolute;
        bottom: 20px;
      }
    }
  }

  .lp-banner-slider {
    height: 120vw;

    &__image {
      height: 100%;

      img {
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .lp-footer {
    &__bottom {
      flex-direction: column;
      align-items: flex-start;
    }
    &__bottom-right {
      align-items: flex-start;
    }
  }
}

@media screen and (max-width: 575.98px) {
  .use-ring {
    .lp-function {
      &__list {
        &-item {
          width: 100%;
          .content {
            margin: 0 0 20px;
          }
        }
      }
    }
  }

  .function-intro-list {
    flex-wrap: wrap;
    .function-intro {
      width: 100%;
      flex-basis: 100%;
      margin-bottom: 16px;
    }
  }

  .lp-specs {
    &__inner {
      padding-left: 20px;
      padding-right: 20px;
    }

    &__list {
      padding-left: 40px;
      padding-right: 40px;
    }

    &__item {
      padding: 8px 0;
    }
    &__ring-img {
      padding: 0 20px;
    }
  }

  .lp-mansory {
    &__item {
      flex-basis: 100%;
    }
  }

  .lp-footer {
    &__inner {
      flex-direction: column;
      align-items: flex-start;
    }
    .social-links {
      order: 2;
      justify-content: flex-end;
      align-items: flex-start;
      margin: 0 -6px;
      flex: 1;
    }

    .links-container {
      order: 3;
      width: 100%;
      flex: none;
    }
    .logo {
      order: 1;
    }

    .group-contact {
      padding-bottom: 0;
    }
  }
}

.lp-user-guide {
  padding-top: 80px;
  padding-bottom: 24px;
  // max-width: 1440px;
}
