.mt-slide-card {
  display: flex;
  overflow: hidden;

  &__front {
    display: flex;
    height: 100%;
    width: 100%;
    position: relative;
  }
  &__back {
    position: absolute;
    display: flex;
    height: 100%;
    width: 100%;
  }
}

.mt-slide-card .mt-slide-card__back-slide {
  opacity: 1;
  transform: translateY(100%);
  transition-timing-function: ease-in;
  transition: 0.25s;
}

.mt-slide-card.mt-slide-card__is-shown .mt-slide-card__back-slide {
  transition: 0.25s;
  transition-timing-function: ease-out;
  transform: translateY(0);
  opacity: 1;
}
