// Poster Back Right Entrance Animation
@-webkit-keyframes temp-opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes temp-opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.pns-landing-page {
  .section-title {
    // mt-display-lg mb-[16px] flex justify-center
    @include text-display(lg);
    display: flex;
    justify-content: center;
    text-align: center;
  }
  &__hero {
    min-height: 100vh;
    height: auto;
    width: 100%;
    background-color: var(--primary-color);
    position: relative;
    display: flex;
    overflow: hidden;

    &-img {
      width: 100%;
      margin-top: calc(var(--header-height));
      min-height: 100vh;
      position: absolute;
      overflow: hidden;
      div {
        width: auto;
        height: 100vh;
        background-size: contain;
        background-image: url("../../assets/images/PNS-hero-2.png");
        background-repeat: no-repeat;
        object-fit: cover;
      }
    }

    &-content {
      position: relative;
      display: flex;
      flex-direction: row;
      gap: 24px;
    }
    &-form {
      container-type: inline-size;
      container-name: form-container;
      padding: 30px;
      margin-top: calc(var(--header-height) + 68px);
      margin-bottom: 170px;
      width: 50%;
      height: fit-content;
      border-radius: 24px;
      animation: 0.8s temp-opacity, 0.8s fade-in-right 0.8s;
      background: var(--white-100, #fcfbfc);
      .input-container {
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin: 16px 0;
      }
      .select-container {
        display: flex;
        flex-direction: row;
        margin: 0 -10px;
        row-gap: 16px;
        .wrapper {
          padding: 0 10px;
          width: 50%;
          flex: 1;
        }
        @container form-container (width < 450px) {
          flex-direction: column;
          .wrapper {
            flex: 1;
            width: 100%;
          }
        }
      }
      .mt-input {
        input {
          @include text-body(xl);
        }
      }
      .subtitle {
        @include text-body(lg);
        margin-bottom: 24px;
        color: var(--black-60);
      }
    }
    &-intro {
      width: 50%;
      display: flex;
      flex-direction: column;
      padding-top: 60vh;
      gap: 12px;
      animation: 0.8s fade-up;
      h1 {
        color: white;
      }
      .view-more-btn {
        border: 2px solid var(--white-1000);
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath fill='white' d='M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: 150px 5px;
        transition: background-position 0.5s ease, padding-left 0.5s ease;
        border-radius: 8px;
        width: 150px;
        height: 40px;
        font-size: 16px;
        padding: 0 10px;

        &:hover {
          transition: background-position 0.5s ease, padding-left 0.5s ease;
          background-position: 113px 5px;
          padding-left: 0;
        }
      }
    }
    &-modal {
      display: flex;
      flex-direction: column;
      align-items: center;

      .mt-text-body-xxl {
        padding-top: 32px;
      }
      .img {
        padding-bottom: 40px;
        width: 283px;
      }
    }
  }
  &__value {
    padding-top: 100px;
    .value-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .content-container {
        max-width: 680px;
        .title {
          font-size: 44px;
          font-style: normal;
          font-weight: 300;
          line-height: 44px;
          margin-bottom: 32px;
        }
        .desc {
          font-size: 24px;
          font-style: normal;
          font-weight: 300;
          line-height: 36px;
        }
      }
      .gif-container {
        min-width: 200px;
      }
    }
  }
  &__partners {
    .images-container {
      display: flex;
      .image-item {
        height: 162px;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .mt-carousel .ant-carousel {
      .slick-track {
        display: flex;
        gap: 24px;
        .slick-slide {
          flex: 1;
          height: 162px;
          img {
            height: auto !important;
            width: auto !important;
            object-fit: contain;
          }
        }
      }
      @include media-breakpoint-up(lg) {
        .slick-track {
          width: 100% !important;
          display: flex;
          .slick-slide {
            flex: 1;
            img {
              max-width: 100% !important;
              width: auto !important;
              padding: 0 !important;
            }
          }
        }
      }
      .slick-dots-bottom {
        bottom: 0;
      }
    }
  }
  &__news {
    &-container {
      display: flex;
      flex-direction: row;
      gap: 24px;
    }
    margin-bottom: 50px;
  }
}

.news-card {
  @include shadow(sm);
  background-color: #fff;
  border-radius: 16px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  min-width: 300px;
  cursor: pointer;

  &:hover {
    transition-duration: 0.8s;
    @include shadow(md);
  }

  &__image {
    height: 50%;
  }
  &__content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 24px;
    height: 100%;
    width: 100%;
  }
  &__title {
    @include text-title(xxl);
    flex: 1;
  }
  &__bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .view-more-btn {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      text-decoration-line: underline;
      background-color: transparent;
      padding: 10px 0;
    }
  }
  &__datetime {
    @include text-body(lg);
    color: var(--font-color-secondary);
  }
}

@include media-breakpoint-down(md) {
  .pns-landing-page {
    &__hero {
      &-img {
        margin-top: 0;
        div {
          width: 150vw;
        }
      }
      &-form {
        margin-bottom: 100px;
      }
    }
    &__value {
      .value-container {
        flex-direction: column;
        gap: 20px;
      }
      .content-container {
        order: 2;
        text-align: center;
      }
      .gif-container {
        order: 1;
        max-width: 300px;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .pns-landing-page {
    &__hero {
      &-content {
        top: 30%;
        flex-direction: column;
      }
      &-intro {
        padding-top: 50vh;
        width: 100%;
      }
      &-form {
        margin: 0;
        width: 100%;
        margin-bottom: 100px;
        .select-container {
          flex-direction: column;
          .wrapper {
            width: 100%;
          }
        }
      }
    }
    &__news {
      &-container {
        flex-wrap: wrap;
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .pns-landing-page {
    &__hero {
      &-img {
        top: 5%;
      }
    }
  }
}
