.landing-page-v2 {
  font-family: "Roboto", sans-serif;
  .page-bg {
    background: url("../../assets/images/homepage/bg.png") no-repeat;
    padding-bottom: 72px;
    background-size: cover;
    background-position: center;
  }
  .contact-us {
    display: flex;
    padding: 64px;
    padding-top: 112px;
    justify-content: center;
    align-items: center;
    gap: 24px;
    @media screen and (max-width: 576px) {
      flex-direction: column;
    }
    .title {
      color: #111318;
      text-align: center;

      font-family: Roboto;
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: 48px; /* 120% */
    }
  }
}

.banner-header {
  position: relative;
  max-height: 1024px;
  .bg {
    height: 100%;
  }
  .content {
    position: absolute;
    inset: 0;
    top: 82px;
  }

  .image {
    height: 650px;
    width: 650px;
    img {
      object-fit: cover;
    }
  }

  .banner-title {
    font-family: Roboto;
    font-size: 64px;
    font-style: normal;
    font-weight: 600;
    line-height: 80px;
  }
  .banner-subtitle {
    font-family: Roboto;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px;
    max-width: 650px;
    margin-bottom: 16px;
  }
  height: 100vh;

  @media screen and (max-width: 1024px) {
    .image {
      height: 320px;
      width: 320px;
    }
  }
  @media screen and (max-width: 768px) {
    .banner-subtitle {
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      text-align: center;
      max-width: 80%;
      margin-bottom: 0;
    }
    .banner-title {
      font-size: 28px;
      font-weight: 600;
      line-height: 36px;
    }
    height: 450px;
    .image {
      height: 150px;
      width: 150px;
    }
  }
}

.do-it-all-banner {
  padding: 56px 0;
  margin-bottom: 56px;
  min-height: 502px;
  display: flex;
  gap: 24px;
  position: relative;
  margin: auto;
  .img-container {
    position: absolute;
    inset: 0;
    right: 52%;
    display: flex;
    justify-content: flex-end;
  }
  .image {
    width: 100%;
    height: 100%;
    img {
      height: 100%;
      object-fit: contain;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: flex-start;
    justify-content: center;
    .title {
      max-width: calc(100vw / 2 - 32px);
    }
    .title-secondary {
      color: rgba(17, 19, 24, 0.45);
      font-family: Roboto;
      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: 58px;
      letter-spacing: -0.25px;
    }
    .title-main {
      color: #000;

      font-family: Roboto;
      font-size: 64px;
      font-style: normal;
      font-weight: 600;
      line-height: 80px;
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 24px;
    .img-container {
      position: static;
      justify-content: center;
      width: 100%;
      aspect-ratio: 2.45;
    }
    .placeholder {
      display: none;
    }
    .content {
      .title {
        max-width: 80%;
      }
    }
  }
  @media screen and (max-width: 576px) {
    flex-direction: column;
    gap: 24px;

    .img-container {
      position: static;
      justify-content: center;
      width: 100%;
      aspect-ratio: 2.45;
    }
    .placeholder {
      display: none;
    }
    .content {
      .title {
        max-width: 80%;
      }
      .title-secondary {
        font-size: 40px;
        line-height: 48px;
      }
      .title-main {
        font-size: 48px;
        line-height: 48px;
      }
      .mt-text-body-xxl {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}

.function-detail {
  position: relative;
  overflow: hidden;
  border-radius: 44px;
  cursor: pointer;
  width: 100%;
  &:hover {
    .image {
      transition: all 0.6s ease-in-out;
      transform: scale(1.05);
    }
  }
  .main {
    aspect-ratio: 3.1;
    width: 100%;
  }
  .secondary {
    aspect-ratio: 0.9;
    width: 100%;
  }
  @media screen and (max-width: 768px) {
    border-radius: 16px;
    .main {
      aspect-ratio: unset;
      height: 250px;
      object-fit: cover;
    }
    .secondary {
      aspect-ratio: unset;
      height: 355px;
    }
  }
  .content {
    position: absolute;
    right: 36px;
    bottom: 36px;
    .icon {
      cursor: pointer;
    }
    &.inner {
      position: absolute;
      right: 24px;
      left: 24px;
      bottom: 24px;
      border-radius: 40px;
      background: rgba(255, 255, 255, 0.8);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px;
    }

    .title {
      color: var(--secondary-color);

      font-family: Roboto;
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: 36px;
      white-space: break-spaces;
    }
  }
}

.intro-images {
  margin-top: 24px;
  margin-bottom: 56px;
  display: flex;
  flex-direction: column;

  .img-container {
    overflow: hidden;
    border-radius: 44px;
    cursor: pointer;

    &:hover {
      .secondary,
      .main {
        transition: all 0.6s ease-in-out;
        transform: scale(1.05);
      }
    }
  }
  .main {
    aspect-ratio: 3.18;
  }
  .secondary {
    aspect-ratio: 0.6;
  }

  .tablet {
    border-radius: 16px;
    flex: unset;
    height: 220px;
    .main,
    .secondary {
      aspect-ratio: unset;
    }
  }

  .mobile {
    border-radius: 16px;
    flex: unset;
    height: 297px;
    .main,
    .secondary {
      aspect-ratio: unset;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .icon {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 40px;
    margin: auto;
    height: 86px;
    display: flex;
    justify-content: center;
    img {
      width: auto;
    }
  }
}
.lifestyle-images {
  display: flex;
  flex-direction: row;
  padding: 56px 0px;
  align-items: center;
  gap: 134px;
  overflow: hidden;
  .image {
    width: 100%;
    aspect-ratio: 0.8;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 992px) {
    flex-direction: column;
    gap: 24px;
  }
  @media screen and (max-width: 576px) {
    .mt-text-body-xl {
      font-size: 16px;
      line-height: 24px;
    }
  }
}

.slider {
  cursor: pointer;
  flex: 1;
  transition: flex 0.8s ease-in-out;
  position: relative;
  border-radius: 44px;
  overflow: hidden;

  &.mid {
    flex: 1.5;
  }

  &.active {
    flex: 2;
    max-width: 526px;
    .bg {
      z-index: 1;
      opacity: 0;
    }
  }
}

.slider-images {
  .imgs {
    display: flex;
    gap: 16px;
    flex-direction: row;
    height: calc(100vh - 82px - 32px);
    max-height: 800px;
    &:hover {
      .slider {
        flex: 1;
      }
      .slider:not(:first-child):not(:last-child) {
        flex: 1.5;
      }

      .bg {
        opacity: 0.45;
      }
    }

    &:hover > *:hover {
      .bg {
        z-index: 1;
        opacity: 0;
      }

      flex: 2;
    }
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: all 0.5s ease-in-out;
    transform: scale(1.05);
  }

  .bg {
    transition: opacity 0.8s ease-in-out;
    position: absolute;
    inset: 0;
    background: rgba(17, 19, 24);
    opacity: 0.45;
    z-index: 1;
  }
  .swiper {
    --swiper-navigation-color: #000;
    --swiper-navigation-size: 24px;

    .swiper-button-prev,
    .swiper-button-next {
      width: 42px;
      height: 42px;
      background: #fff;
      border-radius: 99px;
      transition: all 0.3s ease-in-out;
      opacity: 0;
      @include shadow(sm);
    }
    &:hover {
      .swiper-button-prev,
      .swiper-button-next {
        opacity: 0.8;
      }
    }
  }
  @media screen and (max-width: 768px) {
    &.container {
      max-width: 100%;
      width: 100%;
    }
    .imgs .slider {
      &.mid {
        flex: 2;
      }

      &.active {
        flex: 4;
        max-width: 526px;

        .bg {
          z-index: 1;
          opacity: 0;
        }
      }
    }
  }
}
.partner-images {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 56px;
  border-radius: 44px;
  gap: 40px;
  background-color: white;
  .imgs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 42px;
    justify-content: center;
    align-items: center;
  }
}

.gradient-layer {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 90%;
  background: linear-gradient(
    0deg,
    #111318 2.24%,
    rgba(17, 19, 24, 0.7) 19.64%,
    rgba(17, 19, 24, 0.65) 22.54%,
    rgba(17, 19, 24, 0) 41.89%
  );
}
