.about-us-v2 {
  width: 100%;
  font-family: "Roboto", sans-serif;
  .header-bg {
    background: url("../../assets/images/homepage/banner-blur.png") no-repeat;

    background-size: cover;
    background-position: center;
  }
  .page-header {
    padding-top: 100px;
    padding-bottom: 96px;
    .heading {
      color: var(--secondary-color);
      text-align: center;

      /* Hero Display */
      font-family: Roboto;
      font-size: 64px;
      font-style: normal;
      font-weight: 600;
      line-height: 80px; /* 125% */
      margin-bottom: 36px;
    }
    .content {
      color: var(--secondary-color);
      text-align: center;

      /* Body/XXLarge */
      font-family: Roboto;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 36px; /* 150% */
      white-space: break-spaces;
    }
  }
  .sliders-container {
    padding-bottom: 96px;

    .swiper {
      width: 100%;
      padding-top: 50px;
      padding-bottom: 50px;
    }

    .swiper-slide {
      background-position: center;
      background-size: cover;
      width: 40%;
      height: 468px;
      position: relative;
      overflow: hidden;
    }

    .swiper-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .overlay {
      // inset: 0;
      // position: absolute;
      // background-color: rgba(0, 0, 0, 1);
      // // opacity: 0.5;
      // transition: all 0.8s ease-in-out;
    }

    .swiper-slide.swiper-slide-active .overlay {
      background-color: rgba(0, 0, 0, 0);
      opacity: 0;
    }
    .swiper-button-prev {
      left: 30%;
      width: 40px;
      height: 40px;
      background: url("../../assets/svg/solid/prev.svg");
      &::after {
        content: "";
      }
    }
    .swiper-button-next {
      right: 30%;
      width: 40px;
      height: 40px;
      background: url("../../assets/svg/solid/next.svg");
      &::after {
        content: "";
      }
    }
    .swiper-button-prev,
    .swiper-button-next {
      transition: all 0.4s linear;
      &:hover {
        opacity: 0.6;
      }
    }
  }

  .page-bg {
    background: url("../../assets/images/homepage/bg.png") no-repeat;
    padding-bottom: 72px;
    background-size: cover;
    background-position: center;
  }

  .list-container {
    padding-top: 96px;
    display: flex;
    flex-direction: row;
    gap: 24px;
    .item {
      display: flex;
      width: 416px;
      padding: 24px;
      flex-direction: column;
      align-items: flex-start;

      gap: 10px;
      flex-shrink: 0;
      flex: 1;
      border-radius: 24px;
      border: 1px solid var(--main-border-color);
      background: var(--silver-200);
      min-width: 368px;
    }

    @include media-breakpoint-down(xl) {
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;

      .item {
        flex: none;
        height: 404px;
      }
    }

    @include media-breakpoint-down(sm) {
      .item {
        min-width: 300px;
        width: 100%;
        height: auto;
        min-height: 404px;
      }
    }

    .title {
      color: var(--secondary-color);

      font-family: Roboto;
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: 36px; /* 128.571% */
    }
    .content {
      color: var(--secondary-color);

      font-family: Roboto;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
    }
  }

  .mid-banner {
    margin-top: 112px;
    overflow: hidden;
    .img-text {
      max-width: 720px;
      width: 100%;
      height: auto;
      object-fit: cover;
      margin-bottom: 56px;
    }
    .img-container {
      // margin: 0 -10%;
      position: relative;
      // width: 120%;
      aspect-ratio: 1.35;
      border-radius: 36px;
      overflow: hidden;
    }
    .img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 0.8s ease-in-out;
      &:hover {
        transform: scale(1.05);
      }
    }
    .content-banner {
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 1;
      height: 439px;
      border-radius: 36px;

      background: url("../../assets/images/about/banner-bg.svg") no-repeat;
      background-position-y: bottom;
      background-position-x: center;
      background-size: cover;
      svg {
        fill: radial-gradient(
          73.68% 60.96% at 50.68% 116.76%,
          #000 40%,
          rgba(0, 0, 0, 0) 93%
        );
      }
    }
    .text {
      position: absolute;
      bottom: 44px;
      right: 15%;
      left: 15%;
      z-index: 2;
    }
  }
  .member-infos {
    margin-top: 112px;
    display: flex;
    flex-direction: column;
    gap: 64px;
  }
  .list-members {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 24px;
  }

  .member {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    cursor: pointer;
    &:hover {
      .img {
        img {
          transform: scale(1.05);
        }
        .overlay {
          background-color: rgba(0, 0, 0, 0.2);
        }
      }
    }

    .content {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      gap: 16px;
    }
    .img {
      position: relative;
      height: 196px;
      width: 196px;
      border-radius: 100px;
      overflow: hidden;
      object-fit: cover;
      object-position: top;

      span {
        background-size: cover !important;
        background-position-y: top;
        background-repeat: no-repeat;
      }

      img {
        transition: all 0.8s linear;
        object-fit: cover;
      }

      .overlay {
        transition: all 0.8s linear;
        inset: 0;
        position: absolute;
        background-color: rgba(0, 0, 0, 0);
        opacity: 0.5;
        border-radius: 30px;
      }
    }
    .name {
      color: var(--secondary-color);
      text-align: center;

      font-family: Roboto;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
    }
    .role {
      color: var(--Text-Black-Secondary, rgba(17, 19, 24, 0.45));
      text-align: center;

      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
  .footer-banner {
    margin-top: 56px;
    padding: 112px 0;
    background: var(--Main-Palette-Black-Black-1000, #111318);
    .title {
      color: var(--Text-White-Tertiary, #fcfbfc);
      text-align: center;

      font-family: Roboto;
      font-size: 64px;
      font-style: normal;
      font-weight: 600;
      line-height: 80px;
    }
    .btn button {
      color: #111318;
      background-color: white;
      border: 1px solid #111318;
      &:hover {
        background-color: #111318;
        color: #fcfbfc;
        border: 1px solid #fcfbfc;
      }
    }
  }

  @media screen and (max-width: 767px) {
    -webkit-tap-highlight-color: transparent !important;
    .mid-banner {
      overflow: hidden;
      .content-banner {
        height: 300px;
        // position: static;
        margin: 0;
      }
      .text {
        position: static;
        div {
          color: var(--secondary-color);
        }
      }
    }
    .list-members {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 36px;
    }
    .member {
      min-width: 321px;
    }
    .footer-banner .btn button {
      width: auto;
    }
  }
}

.info-modal {
  max-width: 784px;
  img {
    width: 345px;
    height: 349px;
    border-radius: 24px;
    object-fit: cover;
    object-position: top;
  }
  .role {
    margin-bottom: 18px;
    color: var(--Text-Black-Secondary, rgba(17, 19, 24, 0.45));
  }
}
