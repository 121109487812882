.mt-title {
  color: var(--font-color);
  text-align: center;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 58px;
  letter-spacing: -0.25px;
  padding-top: 16px;
  padding-bottom: 32px;
}

// // body
// .mt-text-body-xxxl {
//   font-size: 32px;
//   font-style: normal;
//   font-weight: 400;
//   line-height: 44px;
// }

// .mt-text-body-xxl {
//   font-size: 24px;
//   font-style: normal;
//   font-weight: 400;
//   line-height: 36px;
// }

// .mt-text-body-xl {
//   font-size: 20px;
//   font-style: normal;
//   font-weight: 400;
//   line-height: 28px;
// }

// .mt-text-body-lg {
//   font-size: 16px;
//   font-style: normal;
//   font-weight: 400;
//   line-height: 24px;
// }

// // heading
// .mt-heading-lg {
//   font-size: 32px;
//   font-style: normal;
//   font-weight: 600;
//   line-height: 40px;
// }

// // title
// .mt-title-lg {
//   font-size: 16px;
//   font-style: normal;
//   font-weight: 500;
//   line-height: 22px;
// }

// // display
// .mt-display-lg {
//   font-size: 48px;
//   font-style: normal;
//   font-weight: 600;
//   line-height: 58px;
//   letter-spacing: -0.25px;
// }

// .mt-display-md {
//   font-size: 40px;
//   font-style: normal;
//   font-weight: 600;
//   line-height: 48px;
// }

@include text-heading-class();
@include text-title-class();
@include text-body-class();
@include text-display-class();
@include text-button-class();
