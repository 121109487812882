.product-v2 {
  overflow: hidden;
  width: 100%;
  font-family: "Roboto", sans-serif;
  .page-bg {
    padding-bottom: 72px;
    background-size: cover;
    background-position: center;
  }
  .header-bg {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    background-color: #111318;

    .top {
      height: 100%;
      max-height: 1020px;
      min-height: 840px;
      width: 100%;
      position: relative;
      container-type: inline-size;
    }

    .light {
      position: absolute;
      top: 180px;
      width: 110vw;
      height: 50vh;
      min-height: 840px;
      margin-left: -5vw;

      img {
        width: 100%;
        height: 100%;
        object-position: top;
        object-fit: contain;
      }
    }

    .half-ring {
      position: relative;
      z-index: 1;
      width: 1150px;
      height: 320px;

      .title {
        position: absolute;
        bottom: 140px;
        right: 0;
        left: 0;
        text-align: center;
        color: var(--Text-White-Tertiary, #fcfbfc);

        font-size: 48px;
        font-weight: 600;
        line-height: 58px;
        letter-spacing: -0.25px;
      }
      span {
        background-size: cover !important;
        background-position-y: bottom !important;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: bottom;
      }
    }
    .lock {
      width: 196px;
      height: 192px;
      padding-top: 64px;
      margin: auto;
      margin-bottom: 100px;

      img {
        height: 100%;
        width: 100%;
        margin: auto;
      }
    }

    .the-ring {
      width: 100%;
      display: flex;
      justify-content: center;
      width: 846px;
      height: 200px;
      margin: auto;

      span {
        background-size: cover !important;
        background-position-y: bottom !important;
      }
      img {
        margin: auto;
        object-fit: contain;
        object-position: center;
      }
    }
  }
  .pannel {
    height: 820px;
    width: 856px;

    img {
      height: 100%;
      object-fit: contain;
    }
    .content {
      position: absolute;
      bottom: 36px;
      right: 0;
      left: 0;
    }
    .title {
      color: white;
    }
    .info-row {
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      &:hover {
        opacity: 0.8;
      }
      padding: 32px;
      display: flex;
      gap: 32px;
      max-width: 485px;
      margin: auto;
      align-items: center;
      border-top: 1px solid var(--main-border-color);
      &:last-child {
        border-bottom: 1px solid var(--main-border-color);
      }
    }
  }
  .box {
    z-index: 1;
    padding: 112px 15px;

    .banner {
      flex: 3;
    }
    .title {
      padding-bottom: 112px;
      color: var(--secondary-color);
      text-align: center;

      font-size: 48px;
      font-weight: 600;
      line-height: 58px; /* 120.833% */
      letter-spacing: -0.25px;
    }
    .index {
      height: 30px;
      width: 30px;
      border-radius: 99px;
      border: 1px solid #111318;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .note {
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-items: center;
      padding-left: 42px;
    }
    :hover {
      img {
        transition: all 0.6s ease-in-out;
        transform: scale(1.05);
      }
    }
  }
  .guides-bg {
    max-height: 1268px;
    height: 100vh;
    min-height: 1000px;
    max-width: 100vw;
    width: 100%;
    position: relative;
    border-radius: 32px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    overflow: hidden;
    .bg {
      position: absolute;
      inset: 0;
      .cover-center {
        background-position: center top;
      }
    }
    .bg img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center top;
    }
  }
  .guides-container {
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 32px;
    margin-bottom: 130px;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 1180px;

    .image {
      height: 180px;
      width: 180px;
      border-radius: 16px;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 140px;
        width: auto;
      }
    }

    .content-container {
      width: 256px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      padding: 32px 24px;
      border-radius: 24px;
      border: 1px solid var(--main-border-color);
      background: var(--Content-Black-60, rgba(17, 19, 24, 0.6));
      transition: all 0.3s linear;
      &:hover {
        background: var(--Content-Black-60, rgba(17, 19, 24, 0.8));
      }
    }
    .content {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
    }
    .title {
      color: var(--white-1000);
      text-align: center;
      font-family: Roboto;
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: 1.4;
      flex: 1;
    }
    .icon {
      width: 36px;
      height: 36px;
      svg {
        fill: var(--white-1000);
      }
    }
  }
  .video {
    padding-top: 112px;
    padding-bottom: 56px;
    .title {
      color: var(--secondary-color);
      text-align: center;

      /* Display/Large */
      font-family: Roboto;
      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: 58px; /* 120.833% */
      letter-spacing: -0.25px;

      margin-bottom: 40px;
    }
    .action {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 40px;
      button {
        width: 100%;
        max-width: 415px;
      }
    }
  }

  .scroll-wrapper {
    display: flex;
    width: 100vw;
    gap: 32px;
    padding: 0 15px;
  }
  @media screen and (max-width: 1024px) {
    .header-bg {
      .top {
        height: auto;
        max-height: unset;
        min-height: unset;
      }
      .half-ring {
        width: 800px;
        height: 300px;
      }
      .the-ring {
        width: 458px;
        height: 108px;
      }
      .light {
        top: 220px;
        // span {
        //   background-position-y: top !important;
        //   background-size: contain !important;
        //   background-repeat: no-repeat;
        // }
      }
      .pannel {
        span {
          background-position-y: center !important;
          background-size: contain !important;
          background-repeat: no-repeat;
        }
      }
    }
    .guides-container .title {
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
    }
    .guides-bg {
      min-height: 0;
    }
  }
  @media screen and (max-width: 768px) {
    .header-bg {
      .lock {
        padding-top: 56px;
        margin-bottom: 68px;

        width: 90px;
        height: 90px;
      }
      .half-ring {
        width: 100vw;
        height: 200px;
        .title {
          bottom: 120px;
        }
      }
      .light {
        top: 120px;
      }
      .the-ring {
        width: 340px;
        height: 80px;
      }
    }
    .pannel {
      width: 464px;
      height: 445px;
      .content {
        bottom: 0px;
      }
      .title {
        @include text-title(lg);
        color: white;
      }
      .info-row {
        padding: 12px;
        display: flex;
        gap: 24px;
        img {
          width: 36px;
        }
      }
    }
    .guides-bg {
      height: 725px;
      .bg img {
        height: 100%;
        object-fit: cover;
      }
    }
    .guides-container {
      gap: 16px;
      height: auto;
      margin-bottom: 44px;
      .image {
        height: 48px;
        width: 48px;
        border-radius: 8px;
        img {
          height: 48px;
          width: auto;
        }
      }
      .content-container {
        width: 224px;
        min-width: unset;
        align-items: flex-start;
      }
      .content {
        flex-direction: row;
        gap: 16px;
      }
      .title {
        flex: 1;
        text-align: left;
      }
    }
  }

  @media screen and (max-width: 576px) {
    .header-bg {
      .half-ring {
        width: 100vw;
        height: 120px;
        .title {
          bottom: 48px;
          font-size: 18px;
          font-weight: 600;
          line-height: 20px;
        }
      }
      .light {
        top: 80px;

        // span {
        //   background-position-y: top !important;
        //   background-size: contain !important;
        //   background-repeat: no-repeat;
        // }

        img {
          width: 100%;
          height: 100%;
          object-position: top;
          object-fit: contain;
        }
      }
      .lock {
        padding-top: 20px;
        margin-bottom: 36px;

        width: 45px;
        height: 45px;
      }
      .pannel {
        width: 287px;
        height: 350px;
      }
    }
    .guides-container {
      .content-container {
        padding: 16px;
        min-height: 186px;

        width: 164px;
        align-items: flex-start;
      }

      .title {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
      }
      .icon {
        width: 24px;
        height: 24px;
        svg {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}

.modal-guide-auto .ant-modal {
  max-width: 1150px;
  width: 100% !important;
  .ant-modal-content {
    @media screen and (max-width: 768px) {
      margin: 24px;
    }
    height: 100%;
    width: auto;
    overflow-y: auto;
  }
}

.modal-info-auto .ant-modal {
  max-width: 1150px;
  width: 100% !important;
  .ant-modal-content {
    width: 100%;
    overflow-y: auto;
    margin: auto;
  }
}
