// @import "../theme-variables.scss";

// .header {
//   @include themify($themes) {
//     background: themed("headerFooterBackground");
//   }
// }

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  // background-color: rgba($color: var(--secondary-color-rgb), $alpha: 0.68);
  background-color: var(--anti-flash);

  // > .container {
  //   max-width: 1440px;
  // }

  &.bg-blur {
    background-color: rgba($color: var(--platium-rgb), $alpha: 0.8);
  }

  &__inner {
    height: var(--header-height);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .left,
    .right {
      flex: 1;
      max-width: calc(50% - 70px);
      position: relative;
      display: flex;
      align-items: center;
    }

    .right {
      margin-left: auto;
      justify-content: flex-end;
    }

    .center {
      position: absolute;
      z-index: 2;
    }
  }

  &__language {
    color: var(--secondary-color);
    text-transform: capitalize;
    padding: 6px 12px 6px 20px;
    cursor: pointer;
    user-select: none;
    font-weight: 500;
    font-family: "Roboto", sans-serif;

    .ant-space-item {
      font-weight: 500;
      + .ant-space-item {
        display: flex;
        svg {
          font-size: 12px;
        }
      }
    }
  }

  .user-icon {
    display: none;
    padding: 0 15px;
    svg {
      width: 20px;
      height: 20px;
      fill: var(--secondary-color);
    }
  }

  .mt-button {
    color: var(--secondary-color);
  }

  .shopping-cart {
    position: relative;
    svg {
      width: 24px;
      height: 24px;
      path {
        stroke: var(--secondary-color);
      }
    }
    &__total {
      position: absolute;
      top: -6px;
      right: -9px;
      width: 20px;
      height: 20px;
      border-radius: 20px;
      background-color: red;
      color: #fff;
      font-size: 12px;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .mt-logo {
    svg {
      path {
        fill: var(--secondary-color);
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .user-icon {
      display: block;
    }

    .user-menu {
      display: flex;
    }
  }
}

.burger-menu {
  width: 16px;
  height: 10px;
  position: absolute;
  z-index: 101;
  // top: 10px;
  left: 0;
  padding: 12px 10px;
  -webkit-tap-highlight-color: transparent;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  &.open {
    > span {
      &:nth-child(1) {
        top: 8px;
        width: 0%;
        left: 50%;
      }
      &:nth-child(2) {
        transform: rotate(45deg);
      }
      &:nth-child(3) {
        transform: rotate(-45deg);
      }
      &:nth-child(4) {
        top: 18px;
        width: 0%;
        left: 50%;
      }
    }
  }
  > span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: var(--secondary-color);
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
    &:first-child {
      top: 4px;
    }
    &:nth-child(2),
    &:nth-child(3) {
      top: 10px;
    }
    &:last-child {
      top: 16px;
    }
  }
}

.user-dropdown-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 14px;
  > * {
    width: 100%;
    .mt-button {
      width: 100%;
    }
  }
  .language-block {
    text-transform: capitalize;
    padding: 6px 12px 6px 20px;
    cursor: pointer;
    user-select: none;
    font-weight: 500;
    text-align: right;
  }
}

.mt-breadcrumb {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &-separator {
    margin: auto 8px;
  }

  &-path {
    color: var(--font-color);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    &:last-child {
      text-decoration: underline;
    }
  }
}
.mt-header {
  &__language {
    padding: 1px 4px 0;
    svg {
      width: 24px;
    }
  }

  &__login-text {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.14px;
  }

  &__dropdown-text {
    display: flex;
    gap: 16px;
    width: 100%;
    padding: 12px;

    > .label {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.4;
    }
    svg {
      height: 24px;
      width: 24px;
    }
  }

  &__profile-dropdown {
    display: flex;
    padding: 8px 16px;
    border-radius: 16px;
    gap: 16px;
    background-color: var(--white-500);
    border: 1px solid var(--main-border-color);
    align-items: center;
    min-width: 160px;
    > span {
      flex: 1;
      padding-right: 16px;

      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.4;
    }
  }

  &__overlay-dropdown {
    min-width: 194px;
    ul {
      border-radius: 16px;
      background-color: var(--white-500) !important;
      border: 1px solid var(--main-border-color) !important;
    }
  }

  @include media-breakpoint-down(sm) {
    &__language svg {
      width: 24px;
    }
  }

  &__drawer {
    // .container {
    //   max-width: 1440px;
    // }
    background: var(--anti-flash) !important;
    .ant-drawer-body {
      padding: 0;
    }
    .link {
      display: flex;
      flex-direction: column;
      gap: 20px;
      div {
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
        // color: var(--font-color-secondary);
        color: var(--font-color);
      }
      // .active {
      //   color: var(--font-color);
      // }
    }

    &-body {
      display: flex;
      flex-direction: row;
      height: 100%;
      padding-top: 64px;
      padding-bottom: 64px;
    }
    &-title {
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: 48px;
      text-align: center;
    }
    &-desc {
      flex: 1;
    }
    &-divider {
      flex: 1 1;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        box-sizing: border-box;
        border-block-start: 1px solid rgba(5, 5, 5, 0.06);
        position: relative;
        display: inline-block;
        height: 100%;
        margin: 0 8px;
        width: 2px;
        vertical-align: middle;
        border-inline-start: 1px solid rgba(5, 5, 5, 0.06);
      }
    }
    &-right {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 36px;
    }
    &-img {
      max-height: 234px;
      max-width: 214px;
      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }
    &-intro {
      width: 65%;
      display: flex;
      flex-direction: row;
      gap: 56px;
    }
  }
}

@include media-breakpoint-down(lg) {
  .mt-header {
    &__drawer {
      &-body {
        padding-top: 16px;
        padding-bottom: 36px;
      }
      &-intro {
        display: flex;
        flex-direction: row;
        gap: 16px;
      }
      &-img {
        max-width: 120px;
      }
      &-divider {
        flex: none;
        width: 10%;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .mt-header {
    &__drawer {
      &-body {
        flex-direction: column;
      }
      &-right {
        gap: 16px;
      }
      &-title {
        font-size: 20px;
        line-height: 28px;
      }
      &-desc {
        // font-size: 16px;
        // line-height: 20px;
      }
      &-intro {
        flex-direction: column;
        gap: 16px;
        width: 100%;
        justify-content: center;
        align-items: center;
      }
      &-divider {
        flex: none;
        height: 44px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          box-sizing: border-box;
          border-block-start: 1px solid rgba(5, 5, 5, 0.06);
          position: relative;
          display: inline-block;
          width: 100%;
          height: auto;
          margin: 0 8px;
          vertical-align: middle;
          border-inline-start: none;
        }
      }
      .link {
        display: flex;
        flex-direction: column;
        gap: 16px;
        div {
          cursor: pointer;

          // font-size: 16px;
          // line-height: 20px;
        }
      }
    }
  }
}

.only-desktop {
  display: flex;
}
.only-mobile {
  display: none;
}

@include media-breakpoint-down(sm) {
  .only-desktop {
    display: none;
  }
  .only-mobile {
    display: block;
  }
}

.ant-drawer {
  // z-index: 999;
  // inset-block-start: 82px;
}
