* {
  box-sizing: border-box;
}

html,
body {
  font-size: 14px;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  //   "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  //   sans-serif;
  font-family: "Roboto", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  box-sizing: border-box;
  width: 100% !important;
  // overflow-y: initial !important;

  color: var(--secondary-color);

  &.overflow-hidden {
    overflow: hidden;
  }
}

video {
  // clip-path: inset(1px 1px);

  &:focus {
    outline: none;
  }
}

#root {
  height: 100%;
}

.pointer {
  cursor: pointer;
}

// .flex {
//   display: flex;
// }

// .italic {
//   font-style: italic;
// }
.secondary-color {
  color: var(--metalic-silver);
}

// .font-bold {
//   font-weight: bold;
// }
