.cart-list {
  padding-top: 30px;
  .title {
    font-size: 16px;
    font-weight: 700;
  }
}
.cart-item {
  .cart {
    display: flex;
    padding: 20px 0;
    border-bottom: 1px solid var(--metalic-silver);

    &:last-child {
      border-bottom: 0;
    }
    &__header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.5rem;
      flex-wrap: wrap;
      .total {
        text-align: end;
      }
    }
    &__price {
      font-size: 16px;
      font-weight: 700;
      .label {
        font-size: 14px;
        font-weight: normal;
        display: inline-block;
        width: 62px;
        padding-right: 5px;
      }

      &.total {
        width: 200px;
        max-width: 200px;
        text-align: right;
      }
    }

    &__title {
      font-size: 16px;
      line-height: 18px;
      font-weight: 700;
      padding-bottom: 0.5rem;
      max-width: 300px;
      min-width: 200px;
    }
    &__image-group {
      display: flex;
      height: fit-content;
    }
    &__image {
      width: 168px;
      height: 168px;
      border-radius: 20px;
      background-color: var(--anti-flash);
    }

    .content {
      padding-left: 20px;
      // overflow: hidden;
      flex: 1 1;
    }

    &__selection {
      display: flex;
      padding-right: 10px;
      align-items: center;
      .ant-checkbox {
        &-inner {
          width: 20px;
          height: 20px;
        }
      }
    }

    &__actions {
      display: flex;
      justify-content: flex-end;
      position: relative;
    }

    &__action {
      width: 40px;
      height: 40px;
      font-size: 22px;
      cursor: pointer;
      transition: all 0.25s ease-in;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: 12px;
      &:hover {
        background-color: var(--anti-flash);
        border-radius: 2rem;
      }
    }

    &__input-group {
      display: flex;
      flex-direction: row;
      .label {
        display: flex;
        align-items: center;
        padding-bottom: 8px;
        width: 30%;
      }
    }

    &__input {
      width: 15rem;
      padding-left: 1rem;
    }
  }
  @include media-breakpoint-down(md) {
    .cart__header {
      flex-direction: column;
      .total {
        text-align: start;
      }
    }
    .cart__title {
      max-width: 200px;
      min-width: 150px;
    }
  }
  @include media-breakpoint-down(sm) {
    margin-right: 0rem;
    .cart__header {
      flex-direction: column;
      .total {
        text-align: start;
      }
    }
    .content {
      padding-left: 1rem;
    }
    .cart__image {
      width: 110px;
      height: 110px;
    }
    .cart__title {
      padding-bottom: 0.5rem;
    }
    .cart__action {
      left: 30px;
      bottom: 24px;
    }
  }
}

.cart-summary {
  padding-top: 30px;
  font-size: 16px;
  flex-basis: 350px;

  .title {
    font-weight: 700;
    padding-bottom: 20px;
  }

  .line {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .ant-divider {
    margin: 15px 0;
    border-color: var(--metalic-silver);
  }
}

.cart-address {
  padding-top: 30px;
  .title {
    font-weight: 700;
    padding-bottom: 10px;
    font-size: 16px;
  }
  .payment-method {
    padding-bottom: 0;
  }
}

// new
.cart-item-new {
  padding-bottom: 24px;
  &:last-child {
    padding-bottom: 0;
  }
  .cart {
    display: flex;
    // border-bottom: 1px solid var(--metalic-silver);

    &:last-child {
      border-bottom: 0;
    }
    &__header {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .total {
        text-align: end;
      }
    }
    &__price {
      display: flex;
      align-items: center;
      &__label {
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
        padding-right: 12px;
        user-select: none;
      }
      &__value {
        padding: 12px 16px;
        border-radius: 8px;
        background-color: var(--silver-200);
        width: 134px;

        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        text-align: center;
      }
    }

    &__title {
      font-size: 24px;
      font-weight: 400;
      line-height: 36px;
    }

    &__image-group {
      display: flex;
      height: fit-content;
    }

    &__image {
      width: 130px;
      height: 130px;
      background-color: var(--silver-200);
      border-radius: 12px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .content {
      padding-left: 24px;
      // overflow: hidden;
      flex: 1 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 16px;
    }

    &__selection {
      display: flex;
      padding-right: 10px;
      align-items: center;
      .ant-checkbox {
        &-inner {
          width: 20px;
          height: 20px;
        }
      }
    }

    &__actions {
      display: flex;
      justify-content: flex-end;
      position: relative;
    }

    &__action {
      width: 40px;
      height: 40px;
      font-size: 22px;
      cursor: pointer;
      transition: all 0.25s ease-in;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      bottom: 12px;
      &:hover {
        background-color: var(--anti-flash);
        border-radius: 2rem;
      }
    }

    &__input-group {
      display: flex;
      align-items: center;
      .label {
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
        padding-right: 12px;
        user-select: none;
      }
    }

    &__input-group + .error-message {
      position: absolute;
      bottom: -16px;
    }

    &__input {
      padding-bottom: 0;
      width: 130px;
      height: 44px;
    }

    &__body {
      display: flex;
      gap: 24px;
      flex-wrap: wrap;
      // justify-content: space-between;
    }
  }

  .mt-select {
    &--size {
      width: 80px;
    }

    .ant-select {
      .ant-select-selector {
        border-radius: 8px;
        height: 44px;
      }
      .ant-select-selection-item {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }

  .mt-input-number {
    .mt-input-number__container {
      .ant-input-number-group {
        border-radius: 8px;
        height: 44px;
        .ant-input-number input {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
        }
      }
    }
  }

  .ant-input-number-input-wrap {
    padding-top: 4px;
  }
}
