// @import ''
.product-detail-card {
  max-width: 50%;
  min-width: 300px;
  border-radius: 16px;
  background: var(--anti-flash);
  padding: 56px;
  flex: 1 1 calc(50% - 12px);
  display: flex;
  position: relative;
  gap: 16px;
  flex-direction: column;

  .mt-text-body-xl {
    padding-bottom: 30px;
  }

  .plus-icon {
    position: absolute;
    right: 40px;
    bottom: 40px;
    svg {
      cursor: pointer;
      height: 30px;
      width: 30px;
    }
  }

  &-modal {
    display: flex;
    flex-direction: column;
    align-items: center;

    .mt-text-body-xl {
      padding-top: 16px;
    }
  }
  &_img {
    padding-bottom: 40px;
    width: 265px;
  }
  &__subcontent {
    display: flex;
    flex-direction: row;
    gap: 39px;
    padding-top: 32px;

    &-item {
      flex: 1;
      padding-left: 30px;

      li {
        list-style-type: disc;
      }
    }
  }

  @media screen and (max-width: 767.98px) {
    max-width: 100%;
    width: 100%;
    &__subcontent {
      display: flex;
      flex-direction: column;
      gap: 0;
    }
  }
}
