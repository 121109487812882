.order-item {
  background-color: var(--anti-flash);
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--metalic-silver);
    margin-bottom: 10px;
  }

  &__list-item {
    display: flex;
    align-items: flex-start;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--metalic-silver);
  }
  &__image {
    width: 100px;
    height: 100px;
    border-radius: 10px;
    background-color: #FFF;
    flex-shrink: 0;
  }
  &__content {
    padding-left: 20px;
    flex: 1;
  }

  &__product-title {
    font-weight: 700;
    font-size: 16px;
  }
}
