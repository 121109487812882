@import "abstracts/functions";
@import "abstracts/variables";
@import "abstracts/theme-variables";
@import "abstracts/mixins";

// @import'base/reset';
@import "base/fonts";
@import "base/animations";
@import "base/preflight";
@import "base/global";

// @import'base/utilities';

@import "./layout/all";

@import "./components/all";
@import "./pages/all";

@import "./tailwind-output.css";

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.show-modal {
  overflow: hidden;
}
