.mt-select {
  padding-bottom: 18px;
  &__label {
    @include text-body(lg);
    padding-bottom: 16px;
  }

  .ant-select {
    width: 100%;
    .ant-select-selector {
      background-color: transparent;
      padding-right: 16px;
      padding-left: 16px;
      height: auto;
      border-width: 1px;
      border-color: var(--metalic-silver) !important;
      border-radius: 16px;
      box-sizing: border-box;
      height: 56px;
      @include text-body(lg);
      overflow: hidden;
      text-overflow: ellipsis;

      .ant-select-selection-search {
        inset-inline-start: 16px;
        inset-inline-end: 16px;
      }

      .ant-select-selection-search-input {
        height: 56px;
      }
    }

    &.ant-select-focused .ant-select-selector,
    .ant-select-selector:hover,
    .ant-select-focused {
      border-color: var(--metalic-silver) !important;
      box-shadow: none !important;
    }
    .ant-select-arrow,
    .ant-select-selection-placeholder {
      color: var(--secondary-color);
      display: flex;
      align-items: center;
    }
    .ant-select-selection-item {
      display: flex;
      align-items: center;
      margin-right: 12px;
      @include text-body(lg);
    }
  }

  &.sm {
    padding-bottom: 8px;
    .ant-select {
      width: 100%;
      .ant-select-selector {
        display: flex;
        align-items: center;
        height: 36px;
        @include text-body(md);
      }

      .ant-select-selection-item {
        display: flex;
        align-items: center;
        @include text-button(md);
      }
    }
  }
}
.ant-select-item-option-content {
  @include text-body(lg);
  padding: 6px 0;
}
