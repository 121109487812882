.checkout-page {
  display: flex;
  justify-content: space-between;
  &__info {
    max-width: 350px;
  }

  &__inner {
    padding-top: 20px;
    width: 100%;

    &-content {
      max-width: 1075px;
      margin: 0 auto;
    }
  }

  .title {
    font-size: 40px;
    font-weight: 600;
    line-height: 48px;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .card {
    padding: 24px;
    margin-bottom: 16px;
    border-radius: 16px;
    background-color: var(--anti-flash);

    &__title {
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
    }
  }

  .ant-divider {
    border-block-start: 1px solid var(--main-border-color);
  }

  .form-info {
    margin-bottom: 16px;
    &--customer {
      margin: -12px -24px 16px;
      display: flex;
      flex-wrap: wrap;
    }

    .mt-select.sm .ant-select .ant-select-selection-item {
      font-size: 20px;
      line-height: 28px;
      font-weight: 400;
    }

    .mt-select.sm .ant-select .ant-select-selector {
      // padding: 0;
      // border-width: 0;
      // border-bottom-width: 1px;
      // border-radius: 0;
      height: 54px;
    }
    .ant-select-selector .ant-select-selection-search,
    .ant-select-selector .ant-select-selection-search-input[type="radio"] {
      height: 54px;
    }

    .mt-select .ant-select .ant-select-arrow,
    .mt-select .ant-select .ant-select-selection-placeholder {
      font-size: 20px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0px;
      text-align: left;
      color: var(--tertiary);
    }
  }

  .form-item {
    width: 100%;
    padding: 12px 24px;
    &--name,
    &--phone {
      width: 50%;
    }
  }

  .ant-radio-wrapper {
    .ant-radio-checked .ant-radio-inner {
    }
  }

  .empty-item-container {
    padding-top: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 18px;
  }

  .add-new-item-container {
    // flex justify-end items-center pt-2 gap-[16px]
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 32px;
    gap: 16px;
  }

  .measure-ring-guide {
    width: 100%;
    .mt-text-body-xl {
      text-align: right;
    }
  }

  @include media-breakpoint-down(md) {
    flex-direction: column;
    justify-content: flex-start;
    &__info {
      max-width: 100%;
    }
  }
}

.checkout-total {
  margin-top: 32px;
  padding-top: 32px;
  border-top: 1px solid var(--main-border-color);
  &__item {
    padding-bottom: 12px;
    display: flex;
    align-items: center;
    .label {
      font-size: 20px;
      font-weight: 400;
      line-height: 28px;
      min-width: auto;
      width: 220px;
    }
    .value {
      padding-left: 40px;
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
    }
  }
  &.total {
    .checkout-total__item {
      .label {
        width: auto;
      }
      .value {
        padding-left: 8px;
        color: var(--error);
      }
    }

    .mt-button {
      max-width: 360px;
      height: 56px;
      width: 100%;
      font-size: 20px;
    }
  }
}
.change-address-modal {
  .address-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-bottom: 24px;
  }
  .address-item {
    border-radius: 8px;
    gap: 16px;
    padding: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: all 0.3s linear;
    border: 1px solid var(--main-border-color);
    background-color: var(--white-1000);

    span:has(.label-container) {
      display: flex;
      flex: 1;
    }

    .label-container {
      flex: 1;
      display: flex;
      flex-direction: row;
      gap: 12px;
      justify-content: space-between;
      align-items: center;
    }
    &.active {
      border: 1px solid var(--primary-color);
    }

    &:hover {
      border: 1px solid var(--primary-color);
      opacity: 0.8;
    }
    .label {
      @include text-title(md);
      margin-bottom: 4px;
    }

    .action-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 12px;
      .action-button {
        transition: all 0.3s;

        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          height: 24px;
          width: 24px;
        }
        :hover {
          cursor: pointer;
          opacity: 0.8;
        }
      }
    }
  }
  .add-new-address {
    &-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    &-btn {
      display: flex;
      flex-direction: row;
      gap: 8px;
      padding: 8px 0;
      cursor: pointer;

      transition: all 0.3s;
      &:hover {
        text-decoration: underline;
        opacity: 0.8;
      }
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
}
.coupon {
  border-width: 4px;
  border-style: solid;
  border-right-style: dotted;
  border-left-style: dotted;
  border-right-width: 3px;
  border-left-width: 3px;

  color: var(--error);
  border-color: var(--danger-color-border);
  padding: 8px 16px;
  background: var(--danger-color-bg);
}
