.modal-w-auto {
  width: auto !important;
}
.member-info-modal {
  @include media-breakpoint-down(md) {
    .modal-w-auto {
      padding: 0 !important;
      width: 100vw;
      height: 100vh;
      max-width: none;
      inset: 0;
    }
    .ant-modal-content {
      width: 100%;
      height: 100%;
    }
  }
}
