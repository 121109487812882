.bs-container,
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .bs-container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .bs-container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .bs-container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .bs-container {
    max-width: 1180px;
  }
  .container {
    max-width: 1180px;
  }
}

@media (min-width: 1400px) {
  .bs-container {
    max-width: 1360px;
  }
  .container {
    // max-width: 1360px;
  }
}
