@import "container";
@import "font";
@import "shadow";

.main-layout {
  &__content {
    padding-top: var(--header-height);
  }
}
.contain-center {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-position: center;
}

.cover-center {
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center;
}
