.mt-ug {
  width: 100%;
  container-type: inline-size;
  container-name: mt-ug-container;
  justify-content: center;
  display: flex;
}

.mt-ugd {
  display: flex;
  flex-direction: column;
  height: 100%;

  .mt-title {
    text-align: center;

    font-size: 40px;
    font-weight: 600;
    line-height: 48px;
  }
  &__container {
    display: flex;
    flex-direction: column;
    gap: 24px;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      gap: 20px;
    }
  }

  &__card {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    border-radius: 16px;
    background: var(--anti-flash);
    &-title {
      width: 100%;
      color: var(--font-color);
      text-align: center;

      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
    }
    &-img {
      height: 257px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: auto;
        object-fit: cover;
      }
    }
    &-desc {
      width: 100%;
      color: var(--font-color);
      text-align: center;

      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
    }
  }
  &__subtitle {
    color: var(--font-color);
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 23px;
  }
  &__title {
    font-size: 42px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    color: var(--secondary-color);

    > span {
      color: var(--metalic-silver);
    }
  }
  &__description {
    color: var(--font-color);
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    br {
      padding: 8px 0;
    }
  }

  &__ur {
    display: flex;
    flex-direction: row;
    background-color: var(--anti-flash);
    border-radius: 16px;
    gap: 44px;
    padding: 36px;

    &-img {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 12px;

      min-width: 425px;
      background-color: #fff;
      border-radius: 8px;
      .lp-mansory__image {
        padding: 0;
      }
    }
    &-content {
      display: flex;
      flex-direction: column;
      min-width: 300px;
      gap: 16px;
    }
    @include media-breakpoint-down(lg) {
      flex-direction: column;
    }
  }
  &__notes {
    color: #000;
    font-size: 12px;
    font-weight: 500;
    padding-top: 34px;
  }

  &__ur-imgs {
    display: flex;
    gap: 24px;
    .ur-img {
      flex: 1;
    }
  }

  &__grs-imgs {
    display: flex;
    justify-content: center;

    gap: 24px;
    .ur-img {
      flex: 1;
      img {
        max-width: 170px;
      }
    }
  }

  &__link-imgs {
    display: flex;
    justify-content: center;

    gap: 24px;
    flex-wrap: wrap;
    .ur-img {
      flex: 1;
      min-width: 300px;
      max-width: 342px;

      height: 350px;
      &.ringSize {
        min-width: 250px;
        max-width: 278px;
        height: auto;
      }
    }
  }
  .access,
  .payment {
    .mt-ugd__card-img {
      height: 150px;
      img {
        max-height: 100%;
      }
    }
  }
  @include media-breakpoint-down(md) {
    .mt-title,
    &__title {
      font-size: 28px;
      font-weight: 600;
      line-height: 36px;
    }
    &__description {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
    &__ur-content {
      min-width: 0;
    }
  }

  @include media-breakpoint-down(sm) {
    &__ur-img {
      min-width: auto;
    }
    &__ur-imgs {
      flex-direction: column;
    }
    .mt-title,
    &__title {
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
    }
    &__description {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  @include media-breakpoint-down(lg) {
    &__grs-imgs {
      flex-wrap: wrap;

      .ur-img {
        flex: 1;
        min-width: 275px;
        max-width: 300px;
      }
    }
  }
  .swiper {
    --swiper-navigation-color: #000;
    --swiper-navigation-size: 24px;

    .swiper-button-prev,
    .swiper-button-next {
      width: 42px;
      height: 42px;
      background: #fff;
      border-radius: 99px;
      transition: all 0.3s ease-in-out;
      opacity: 0;
      @include shadow(sm);
    }
    &:hover {
      .swiper-button-prev,
      .swiper-button-next {
        opacity: 0.8;
      }
    }
  }
}

.mt-ug {
  &__list {
    display: flex;
    gap: 24px;
    height: 100%;
  }

  &__group {
    display: flex;
    flex: 1 1 calc(50% - 16px);
    // flex: 1;
  }

  .col-3 {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  > .container {
    padding-left: 0;
    padding-right: 0;
  }
  &__item {
    width: 100%;
    height: 100%;
    .inner {
      overflow: hidden;
      width: 100%;
      background-color: var(--anti-flash);
      border-radius: 16px;
      height: 100%;
      padding: 36px;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      min-height: 280px;
    }
  }

  &__title {
    color: var(--font-color);
    text-align: center;

    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.2;
    white-space: break-spaces;

    &.text-left {
      text-align: left;
    }
  }
  &__subtitle {
    padding-top: 10px;
    color: var(--font-color-secondary);
    text-align: center;

    font-size: 18px;
    line-height: 1.2;
    font-style: normal;
    font-weight: 400;
    &.text-left {
      text-align: left;
    }
  }
  .small-header-container {
    display: flex;
    flex: 1;
    gap: 16px;
    svg {
      width: 40px;
    }
  }

  &__image {
    padding-bottom: 62px;
    padding-top: 112px;
    max-width: 270px;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    > span {
      font-size: 12px;
      font-weight: 500;
      font-style: italic;
      padding-top: 30px;
      text-align: center;
      display: block;
    }
  }
  .plus-icon-filled {
    display: flex;
    font-size: 38px;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    bottom: 32px;
    cursor: pointer;
  }

  .lp-function__list-item {
    padding: 0;

    img {
      width: 90%;
      max-width: none;
    }
  }
}
@media screen and (max-width: 1024px) {
  .mt-ug {
    height: auto;
    padding-bottom: 16px;

    &__item {
      max-width: 400px;
    }
    &__list {
      display: flex;
      flex-wrap: wrap;
    }

    .col-3 {
      flex: none;
      flex-direction: row;
      width: 100%;
      .item-3,
      .item-4 {
        flex: 1;
      }
    }

    &__image {
      span {
        display: none;
      }
    }
  }
}

@container mt-ug-container (width < 650px) {
  .mt-ug {
    display: flex;
    &__item {
      flex-basis: 100%;
    }
    &__list {
      max-width: 400px;
      flex-direction: column;
    }
    .col-3 {
      flex: none;
      flex-direction: column;
      width: 100%;
    }
  }
}
