// Breakpoints
@mixin smMinBreakPoint {
  @media (min-width: #{$screen-sm}) {
    @content;
  }
}

@mixin mdMinBreakPoint {
  @media (min-width: #{$screen-md}) {
    @content;
  }
}

@mixin lgMinBreakPoint {
  @media (min-width: #{$screen-lg}) {
    @content;
  }
}

@mixin xlMinBreakPoint {
  @media (min-width: #{$screen-xl}) {
    @content;
  }
}

@mixin xxlMinBreakPoint {
  @media (min-width: #{$screen-xxl}) {
    @content;
  }
}

@mixin customMinBreakPoint($size) {
  @media (min-width: $size+"px") {
    @content;
  }
}

@mixin customMaxBreakPoint($size) {
  @media (max-width: $size+"px") {
    @content;
  }
}

// Breakpoint viewport sizes and media queries.
//
// Breakpoints are defined as a map of (name: minimum width), order from small to large:
//
//    (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px)
//
// The map defined in the `$grid-breakpoints` global variable is used as the `$breakpoints` argument by default.

// Name of the next breakpoint, or null for the last breakpoint.
//
//    >> breakpoint-next(sm)
//    md
//    >> breakpoint-next(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    md
//    >> breakpoint-next(sm, $breakpoint-names: (xs sm md lg xl))
//    md
@function breakpoint-next(
  $name,
  $breakpoints: $grid-breakpoints,
  $breakpoint-names: map-keys($breakpoints)
) {
  $n: index($breakpoint-names, $name);
  @return if(
    $n != null and $n < length($breakpoint-names),
    nth($breakpoint-names, $n + 1),
    null
  );
}

// Minimum breakpoint width. Null for the smallest (first) breakpoint.
//
//    >> breakpoint-min(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    576px
@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}

// Maximum breakpoint width. Null for the largest (last) breakpoint.
// The maximum value is calculated as the minimum of the next one less 0.02px
// to work around the limitations of `min-` and `max-` prefixes and viewports with fractional widths.
// See https://www.w3.org/TR/mediaqueries-4/#mq-min-max
// Uses 0.02px rather than 0.01px to work around a current rounding bug in Safari.
// See https://bugs.webkit.org/show_bug.cgi?id=178261
//
//    >> breakpoint-max(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    767.98px
@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
  $next: breakpoint-next($name, $breakpoints);
  @return if($next, breakpoint-min($next, $breakpoints) - 0.02, null);
}

// Returns a blank string if smallest breakpoint, otherwise returns the name with a dash in front.
// Useful for making responsive utilities.
//
//    >> breakpoint-infix(xs, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    ""  (Returns a blank string)
//    >> breakpoint-infix(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    "-sm"
@function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
  @return if(breakpoint-min($name, $breakpoints) == null, "", "-#{$name}");
}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media of at most the maximum breakpoint width. No query for the largest breakpoint.
// Makes the @content apply to the given breakpoint and narrower.
@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media that spans multiple breakpoint widths.
// Makes the @content apply between the min and max breakpoints
@mixin media-breakpoint-between(
  $lower,
  $upper,
  $breakpoints: $grid-breakpoints
) {
  $min: breakpoint-min($lower, $breakpoints);
  $max: breakpoint-max($upper, $breakpoints);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($lower, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include media-breakpoint-down($upper, $breakpoints) {
      @content;
    }
  }
}

// Media between the breakpoint's minimum and maximum widths.
// No minimum for the smallest breakpoint, and no maximum for the largest one.
// Makes the @content apply only to the given breakpoint, not viewports any wider or narrower.
@mixin media-breakpoint-only($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  $max: breakpoint-max($name, $breakpoints);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($name, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include media-breakpoint-down($name, $breakpoints) {
      @content;
    }
  }
}

@mixin text-title($name, $titlesize: $text-title) {
  $size: map-get($titlesize, $name);
  font-size: $size + px;
  font-weight: 500;

  @if $size <= 12 {
    line-height: ($size + 4) + px;
  } @else if $size <= 16 {
    line-height: ($size + 6) + px;
  } @else {
    line-height: ($size + 8) + px;
  }
}

@mixin text-heading(
  $name,
  $headingsize: $text-heading,
  $lineheights: $text-heading-line-height
) {
  $size: map-get($headingsize, $name);
  $lineheight: map-get($lineheights, $name);

  font-size: $size + px;
  line-height: $lineheight + px;
  font-weight: 500;
}

@mixin text-heading(
  $headingvalue,
  $headingsize: $text-heading,
  $lineheights: $text-heading-line-height
) {
  $size: map-get($headingsize, $headingvalue);
  $lineheight: map-get($lineheights, $headingvalue);

  font-size: $size + px;
  line-height: $lineheight + px;

  font-style: normal;
  font-weight: 600;
  color: var(--font-color);
}

@mixin text-display(
  $name,
  $displaysize: $text-display,
  $lineheights: $text-display-line-height
) {
  $size: map-get($displaysize, $name);
  $lineheight: map-get($lineheights, $name);

  font-size: $size + px;
  line-height: $lineheight + px;

  font-style: normal;
  font-weight: 600;
  color: var(--font-color);
}

@mixin text-body(
  $name,
  $bodysize: $text-body,
  $lineheights: $text-body-line-height
) {
  $size: map-get($bodysize, $name);
  $lineheight: map-get($lineheights, $name);

  font-size: $size + px;
  line-height: $lineheight + px;

  font-style: normal;
  font-weight: 400;
}

@mixin text-title(
  $name,
  $titlesize: $text-title,
  $lineheights: $text-title-line-height
) {
  $size: map-get($titlesize, $name);
  $lineheight: map-get($lineheights, $name);

  font-size: $size + px;
  line-height: $lineheight + px;
  font-style: normal;
  font-weight: 500;
  color: var(--font-color);
}

@mixin text-button(
  $name,
  $button-size: $text-button,
  $lineheights: $text-button-line-height,
  $spacings: $letter-spacing-button
) {
  $size: map-get($button-size, $name);
  $lineheight: map-get($lineheights, $name);
  $spacing: map-get(
    $map: $spacings,
    $key: $name,
  );
  font-size: $size + px;
  line-height: $lineheight + px;
  letter-spacing: $spacing;
  font-weight: 600;
}

@mixin text-title-class() {
  @each $name, $val in $text-title {
    .mt-title-#{$name} {
      @include text-title($name);
    }
  }
}

@mixin text-heading-class() {
  @each $name, $val in $text-heading {
    .mt-heading-#{$name} {
      @include text-heading($name);
    }
  }
}

@mixin text-body-class() {
  @each $name, $val in $text-body {
    .mt-text-body-#{$name} {
      @include text-body($name);
    }
  }
}

@mixin text-display-class() {
  @each $name, $val in $text-display {
    .mt-display-#{$name} {
      @include text-display($name);
    }
  }
}

@mixin text-button-class() {
  @each $name, $val in $text-button {
    .mt-text-button-#{$name} {
      @include text-button($name);
    }
  }
}

@mixin grid($col) {
  width: calc(100% / 12 * $col);
}
