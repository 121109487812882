.gba-page {
  padding-top: 20px;
  padding-bottom: 60px;
  .mt-breadcrumb {
    padding-bottom: 24px;
  }
  .card {
    padding: 24px;
    margin-bottom: 16px;
    border-radius: 16px;

    &__title {
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
    }

    width: 100%;
    @include media-breakpoint-up(xl) {
      width: 66%;
    }
  }

  .mt-table {
    thead tr,
    tbody tr:not(:last-child) {
      border-bottom: 1px solid var(--main-border-color);
    }

    .cell {
      padding: 24px 10px;
      @include text-body(lg);
    }

    th .cell {
      font-weight: 600;
    }
  }

  .add,
  .minus {
    border-radius: 16px;
    padding: 4px 16px;
    background: var(--support-success);
    color: #fff;
    text-align: center;
  }

  .minus {
    background: var(--error);
  }
  .info-card {
    &:not(:last-child) {
      padding-bottom: 16px;
      border-bottom: 1px solid var(--main-border-color);
    }
  }

  .row-info {
    display: flex;
    flex-direction: row;
    gap: 8px;

    @include text-body(lg);

    .title {
      font-weight: 500;
      max-width: 100px;
      width: 100%;
    }
  }

  &.auth-page__title {
    padding-bottom: 0px;
  }
  &.faq-detail-subtitle {
    padding-bottom: 24px;
  }
}
