// Poster Back Right Entrance Animation

.agency-page {
  display: flex;
  min-height: 100vh;
  padding-top: var(--header-height);

  &__content {
    display: flex;
    flex-direction: row;
    padding: 40px 0;
    justify-content: space-around;
    align-items: center;
    flex: 1 1;
  }

  &__overview {
    flex: 1;
    .title {
      padding-top: 48px;
      text-align: center;
    }
  }
  &__form {
    flex: 1;
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;

    .mt-form {
      max-width: 460px;
      width: 100%;
      padding: 40px;
    }

    .subtitle {
      @include text-body(lg);
      color: var(--black-60);
      padding-top: 12px;
      padding-bottom: 24px;
    }
  }

  @include media-breakpoint-down(md) {
    &__content {
      flex-direction: column;
      row-gap: 16px;
      padding-bottom: 36px;
      padding-top: 24px;
      justify-content: flex-start;
      .title {
        @include text-title(xl);
        padding-top: 26px;
      }
      .title1 {
        @include text-title(lg);
      }
    }
    &__form {
      order: 1;
      flex: none;
      .mt-form {
        max-width: 460px;
        width: 100%;
        padding: 20px;
      }
    }
    &__overview {
      order: 0;
      flex: none;
      flex-direction: column;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      padding: 16px 0;
      .lp-function__list-item {
        width: 80%;
        flex: none;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
}

@include media-breakpoint-down(xs) {
}

.mt-form {
  container-type: inline-size;
  container-name: form-container;
  padding: 30px;
  border-radius: 24px;
  background: var(--white-100, #fcfbfc);
  transition: all 0.3s ease;
  &:hover {
    @include shadow(md);
  }
}
