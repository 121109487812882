.mt-button {
  background-color: transparent;
  border: 0;
  padding: 0px 16px;
  height: 32px;
  border-radius: 30px;
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  outline-style: none;
  user-select: none;

  .ant-btn-loading-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  &.disabled {
    opacity: 0.75;
  }

  &.primary {
    background-color: var(--secondary-color);
    border: 2px solid var(--secondary-color);
    color: #fff;
    transition: 0.25s ease-in;

    &:not(.disabled):hover {
      background-color: #fff;
      color: var(--secondary-color);
      border-color: var(--secondary-color);
    }
  }

  &.white {
    background-color: #fff;
    color: var(--secondary-color);

    &:hover {
      background-color: #fff;
      color: var(--secondary-color);
    }
  }

  &.outline {
    background-color: #fff;
    border: 2px solid var(--secondary-color);
    color: var(--secondary-color);

    &:not(.disabled):hover {
      background-color: var(--secondary-color);
      border: 2px solid var(--secondary-color);
      color: #fff;
    }
  }

  &.light-on-dark {
    background-color: transparent;
    border: 2px solid #fff;
    color: #fff;

    &:hover {
      background-color: transparent;
      border: 2px solid #fff;
      color: #fff;
    }
  }
}

.close-icon {
  width: 26px;
  height: 26px;
  border-radius: 26px;
  border: 2px solid var(--secondary-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.mt-button-lg {
  height: 56px;
  @include text-button(lg);
}

.mt-button-md {
  height: auto;
  @include text-button(md);
  padding: 12px 24px;
}
