.address-input {
  .mt-input {
    .peer {
      font-size: 16px;
      &:placeholder-shown {
        ~ label {
          font-size: 16px;
        }
      }
      &:focus {
        ~ label {
          font-size: 14px;
        }
      }
    }
  }
}
.address-popover {
  .ant-popover-inner {
    border-radius: 0;
    padding: 0;
  }
  .ant-tabs {
    > .ant-tabs-nav {
      margin-bottom: 0;
    }
    .ant-tabs-nav-list {
      justify-content: space-between;
      flex: 1;
    }
    .ant-tabs-tab {
      padding-left: 16px;
      padding-right: 16px;
      margin: 0;
      flex: 1;
      justify-content: center;

      // :where(.css-dev-only-do-not-override-1b6p96h).ant-tabs .ant-tabs-tab.

      &:hover:not(.ant-tabs-tab-disabled),
      &.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: var(--primary-color);
      }
    }

    .ant-tabs-ink-bar {
      background: var(--primary-color);
    }
  }

  .address-item {
    padding: 8px 16px;
    transition: all 0.3s ease;
    cursor: pointer;
    &.active,
    &:hover {
      background-color: var(--silver-100);
    }
  }
}
