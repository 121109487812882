.festival {
  .mt-button {
    height: 56px;
    font-size: 20px;
  }

  .input {
    label {
      color: var(--tertiary);
      font-size: 20px;
      font-weight: 400;
    }
    input {
      font-size: 20px;
    }
  }
}
