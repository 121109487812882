.auth-page {
  padding-bottom: 44px;
  padding-top: 44px;
  &__title {
    color: var(--primary-color);
    @include text-display(lg);
    text-align: center;
    padding-bottom: 32px;
  }

  .form {
    .mt-button {
      height: auto;
      min-width: 120px;
      text-align: center;
    }
  }

  .otp-container {
    justify-content: space-between;
  }
}
