.product {
  background-color: var(--anti-flash);
  width: 100%;

  .breadcrumb {
    padding-top: 20px;
    padding-bottom: 40px;
    background-color: var(--white-1000);
  }

  &-intro {
    display: flex;
    padding-top: 48px;
    padding-bottom: 48px;

    &__left {
      width: 50%;
      margin-right: 24px;
      background-color: #fff;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__right {
      width: 50%;
    }

    &__image {
      // background-color: #fff;
      // border-radius: 20px;
      height: 100%;
      width: 100%;
      .mt-carousel .ant-carousel .slick-slide img {
        width: 486px !important;
        height: auto;
        padding: 50px;

        @include media-breakpoint-only(md) {
          width: 50% !important;
        }
      }
    }

    &__title {
      @include text-display(md);
      padding-bottom: 12px;
    }

    &__price {
      // font-size: 26px;
      // line-height: 30px;
      padding-bottom: 24px;
      // font-weight: 700;
    }

    &__desc {
      // font-size: 16px;
      // line-height: 18px;
      // white-space: break-spaces;
      padding-bottom: 24px;
    }

    &__form {
      display: flex;
      flex-direction: row;
      gap: 16px;
      max-width: 100%;
    }

    &__form-number {
      // max-width: 8rem;
      flex: 1;

      .mt-input-number__container {
        width: 100%;
      }
    }

    &__form-select {
      flex: 1;

      .mt-select__label {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .sub-label {
          font-weight: 400;
          font-size: var(--font-sm);
          text-decoration: underline;

          &:hover {
            text-decoration: none;
            cursor: pointer;
          }
        }
      }

      .ant-select {
        // max-width: 14rem;

        flex: 1;
      }
    }

    .mt-button {
      margin-top: 24px;
      width: 100%;
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: 36px;
      height: auto;
      padding: 16px;
      border-radius: 100px;

      @include text-title(xl);
      color: var(--white-1000);
    }

    &__pre-order {
      margin-top: 18px;
      border-radius: 16px;
      border: 1px solid var(--main-border-border);
      background: var(--silver-300);
      padding: 24px;

      .desc {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 12px;
        svg {
          height: 32px;
          width: 32px;
        }
      }
      .order-link {
        margin-top: 4px;
        margin-left: 46px;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 36px;
        text-decoration-line: underline;
      }
    }
  }

  &-detail {
    background-color: #fff;
    display: flex;
    align-items: center;
    padding-bottom: 60px;

    &__section {
      padding-top: 80px;
      display: flex;
      flex-direction: column;
      gap: 40px;
    }

    &__title {
      text-align: center;
      @include text-heading(xl);
    }

    &__cols {
      flex-wrap: wrap;
      display: flex;
      justify-content: center;
      gap: 24px;
    }

    &__item-title {
      font-size: var(--font-md);
      font-weight: 700;
      text-align: center;
      margin: 20px 0;
    }

    &__br {
      background-color: var(--metalic-silver);
      height: 1px;
      margin: 20px 4rem;
    }

    &__video {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 40px;

      &-items {
        display: flex;
        flex-wrap: wrap;
        gap: 40px;
        justify-content: center;
        position: relative;
      }

      &-item-container {
        flex: 1;
        max-width: 366px;
        padding: 32px 60px;
        cursor: pointer;
        background: var(--anti-flash);
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 32px;
      }

      // &-item-container:hover &-item-thumbnail img {
      //   opacity: 0.06;
      // }

      // &-item-container:hover &-item-description {
      //   opacity: 1;
      // }

      &-item-thumbnail {
        max-width: 146px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          object-fit: fill;
        }
      }

      &-item-description {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        @include text-title(lg);
      }

      &-wrapper {
        width: 100%;
        padding: 24px;
        gap: 10px;
        border-radius: 16px;
        background: var(--anti-flash);
      }
    }

    &__size-chart {
      padding: 60px;
      padding-top: 0;
      text-align: center;
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    &__summary {
      padding: 0 60px;
    }

    &__item-content {
      color: var(--font-color);
      text-align: center;
      font-size: 26px;
      font-weight: 700;
      letter-spacing: 1px;
    }
  }

  .detail-card {
    width: 340px;
    height: 430px;

    padding-bottom: 0;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    border-radius: 20px;
    background: var(--anti-flash);

    &-front {
      display: flex;
      flex: 1;
      width: 340px;
      height: 430px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 20px;
      background: var(--anti-flash);
      position: relative;

      &-content {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 56px;
        padding-bottom: 90px;
      }

      &-title {
        color: var(--font-color);
        text-align: center;
        font-size: 42px;
        font-weight: 700;
        margin-bottom: 20px;
        line-height: normal;
        letter-spacing: 1.68px;
      }
      &-subtitle {
        color: var(--font-color-secondary);
        text-align: center;
        font-size: 26px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 1.04px;
      }
    }
    &-back {
      display: flex;
      flex: 1;
      padding: 26px;
      padding-bottom: 30px;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      border-radius: 20px;
      background: var(--anti-flash);
      &-content {
        text-align: left;
        color: var(--font-color);
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.64px;
      }
    }
    .icon-container {
      width: 100%;
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: 30px;
      right: 0;
    }
  }

  // .mt-button {
  //   height: 40px;
  // }

  @include media-breakpoint-down(lg) {
    .product-detail__video {
      padding: 0;
    }
    &-intro__left {
      margin-right: 36px;
    }
  }

  @include media-breakpoint-down(md) {
    .product-detail__video {
      &-item-container {
        width: 300px;
      }
      &-wrapper {
        padding: 4px;
      }
    }
    .detail-card {
      width: 100%;
      max-width: 340px;
      min-height: auto;
      &-front-content {
        padding: 0 26px;
        padding-bottom: 90px;
      }
    }
  }

  @media screen and (width < 1024px) {
    .product-intro {
      flex-direction: column;
      align-items: center;
      padding-bottom: 0;

      &__left,
      &__right {
        width: 100%;
        margin-bottom: 3rem;
        margin-right: 0;
        max-width: none;
      }
      &__right {
        padding-left: 0rem;
      }
      &__form {
        gap: 16px;
      }
      &__image {
        max-width: 100%;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .product-detail__summary {
      padding: 0;
    }
    .product-detail__size-chart {
      padding: 0;
    }
    .product-detail__cols {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }

    .product-detail__video {
      padding: 0;

      &-items {
        flex-direction: column;
      }
      .mt-media__video {
        padding-top: 75%;
      }

      &-thumbnail {
        img {
          max-height: 300px;
        }
      }

      &-item {
        width: 176px;
        margin: 3rem 0;
      }
    }
  }
}

.measure-ring-guide {
  position: relative;

  .overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 0;
    top: 0;
    left: 0;
  }

  .modal {
    position: absolute;
    top: 40px;
    left: 0;
    background: var(--silver-100);
    width: 100%;
    z-index: 900;
    padding: 32px 24px;
    border-radius: 16px;
    @include shadow(md);
    @media screen and (min-width: 1440px) {
      width: 690px;
    }
  }

  .result-title {
    @include text-title(xxl);
    text-align: center;
    padding-bottom: 12px;
  }
  .guide-wrapper {
    container-type: inline-size;
    container-name: wrapper;
  }
  .input-wrapper-row {
    display: flex;
    flex-direction: row;
    gap: 32px;
    @container wrapper (width < 350px) {
      flex-direction: column;
    }
  }
  .guide-row {
    display: flex;
    flex-direction: row;
    gap: 12px;

    container-type: inline-size;
    container-name: container-row;

    @container wrapper (width < 600px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .guide-col {
        width: 100%;
        flex-direction: row;

        @container container-row (width < 450px) {
          flex-direction: column;
          display: flex;

          width: 100%;
          align-items: center;
          .guide-card {
            width: 100%;
          }
        }
      }
    }
  }
  .guide-col {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .guide-card {
    max-width: 300px;
    flex: 1;
    display: flex;
    padding: 12px;
    align-items: center;
    border-radius: 8px;
    background: var(--main-palette-white-white-1000, #fcfbfc);
    justify-content: center;
    border-radius: 16px;
    &.desc {
      gap: 12px;
      min-height: 40px;
      align-items: flex-start;
    }
    &.img {
      padding: 24px;
    }
  }

  .divider {
    margin: 24px;
  }
}

.plus-icon-rotate {
  font-size: 38px;
  opacity: 1;
  transform: rotate(45deg);
  transition: 0.2s;
  -webkit-tap-highlight-color: transparent;
  > svg {
    background-color: #fff;
    border-radius: 100%;
  }
}

.plus-icon-rotate.rotate {
  opacity: 1;
  transform: rotate(0);
  transition: 0.2s;
}
.close-icon-rotate {
  font-size: 38px;
  opacity: 1;
  transform: rotate(-45deg);
  transition: 0.2s;
  -webkit-tap-highlight-color: transparent;
  > svg {
    background-color: #fff;
    border-radius: 100%;
  }
}
.close-icon-rotate.rotate {
  transform: rotate(0);
  opacity: 1;
  transition: 0.2s;
}

.ant-image-preview-root .ant-image-preview-img {
  background-color: white;
  padding: 8px;
  border-radius: 8px;
}
