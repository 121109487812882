.mt-comming-soon {
  width: 100%;
}
.mt-contact {
  padding-top: 20px;
  padding-bottom: 36px;
  &__container {
    padding-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 24px;
  }
  &__card {
    flex: 1 1;
    max-width: 586px;
    border-radius: 16px;
    background: var(--anti-flash);
    padding: 32px 48px;
    @include shadow(sm);
    @include media-breakpoint-down(sm) {
      padding: 32px;
    }
  }

  .contact {
    padding-top: 16px;
    display: flex;
    gap: 24px;
    flex-direction: column;
    container-type: inline-size;

    .contact-group {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 8px;
      justify-content: center;

      .contact-label {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        min-width: 110px;
        display: flex;
        gap: 8px;
        align-items: center;

        svg {
          width: 24px;
          height: 24px;
        }
      }
      .contact-content {
        display: flex;
        flex: 1;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
      }
      @container (width < 450px) {
        flex-wrap: wrap;
        justify-content: flex-start;
        .contact-label {
          width: 100%;
        }
      }
    }
  }

  &__title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 24px;
    svg {
      width: 40px;
      height: 40px;
    }
  }

  @include media-breakpoint-down(md) {
    &__container {
      flex-wrap: wrap;
    }
    &__card {
      width: 100%;
      flex: none;
    }
  }
}

.mt-about-us {
  padding-top: 20px;
  padding-bottom: 36px;
  width: 100%;

  &__container {
    padding-top: 40px;
    display: flex;
    flex-direction: column;
  }

  &__sections {
    display: flex;
    flex-direction: column;
    gap: 160px;
  }

  &__section-1 {
    display: flex;
    flex-direction: row;
    gap: 59px;
    .video {
      border-radius: 16px;
      background-color: var(--anti-flash);
      padding: 2px;

      @include grid(7);
    }
    .desc {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 32px;
    }
  }
  &__section-2 {
    display: flex;
    width: 100%;
    background-color: var(--anti-flash);
    padding: 56px 0;

    .image {
      width: 90px;
      display: flex;
      width: 100px;
      height: 100px;
      padding: 5px;
      justify-content: center;
      align-items: center;
      border-radius: 16px;
      border: 1px solid var(--main-border-color);
      background: var(--white-200);
      margin-bottom: 16px;
    }
    .body {
      padding-top: 8px;
    }
  }
  &__list {
    padding-top: 32px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
  }
  &__card {
    flex: 1;
    padding: 24px;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--main-border-color, #d7d8db);
    border-radius: 16px;
    background: var(--silver-200, #eaeaec);
  }

  @include media-breakpoint-down(lg) {
    &__card {
      max-width: 460px;
    }
    &__list {
      flex-direction: column;
      align-items: center;
    }
    &__section-2 {
      justify-content: center;
      .mt-display-sm {
        text-align: center;
      }
    }
    &__section-1 {
      gap: 24px;
    }
  }
  @include media-breakpoint-down(md) {
    &__section-1 {
      flex-direction: column;
      gap: 24px;

      .video {
        width: 100%;
      }
    }
    &__section-2 {
      padding: 56px 0;
    }
  }
}

.member-card-container {
  container-type: inline-size;
  container-name: member-card;
  margin: 0 12px;

  .member-card {
    @include shadow(sm);
    background: var(--silver-50);
    height: 380px;
    border-radius: 16px;
    padding: 24px 16px;
    gap: 24px;

    display: flex;
    flex-direction: row;

    .image-container {
      width: 50%;
      height: 100%;
      overflow: hidden;
      border-radius: 12px;
    }
    .desc-container {
      display: flex;
      flex-direction: column;
      width: 50%;
      overflow: hidden;
      text-overflow: ellipsis;
      .mt-text-body-lg,
      .mt-text-body-md {
        color: var(--font-color-secondary);
      }
    }
  }

  @container member-card (width < 600px) {
    .member-card {
      height: 670px;
      flex-direction: column;
      .image-container {
        height: 380px;
      }
      .image-container,
      .desc-container {
        width: 100%;
      }
    }
  }
  @container member-card (width < 500px) {
    .member-card .image-container {
      height: 320px;
    }
  }
  @container member-card (width < 360px) {
    .member-card .image-container {
      height: 240px;
    }
  }
}
.swiper {
  .swiper-slide {
    width: 630px;

    @media screen and (max-width: 630px) {
      width: 100%;
      .member-card-container .member-card {
        flex-direction: column;
      }
    }
    .member-card {
      opacity: 0.6;
    }
    &-active {
      .member-card {
        opacity: 1;
      }
    }
  }
  .swiper-pagination-bullet {
    --swiper-theme-color: var(--secondary-color);
    --swiper-pagination-bullet-size: 10px;

    transition: opacity 0.8s ease-in-out;
    &:hover {
      opacity: 0.8;
    }
  }
}
